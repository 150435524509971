import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { ACTIONS, APIS, STORAGE } from '../common/config/config';
function* setLoader(status) {
  yield put({
    type: status ? ACTIONS.LOADER.SHOW_LOADER : ACTIONS.LOADER.HIDE_LOADER,
  });
}

export function* getWorkOrderList(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.GET, APIS.WORKORDER.ORDER_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield put({
        type: ACTIONS.WORKORDER.ORDER_LIST,
        payload: resp.data,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* saveWorkOrder(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.WORKORDER.ORDER_SAVE, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* uploadSingleFile(action) {
  try {
    yield setLoader(true);
    const data = action.payload.formData;
    const resp = yield call(CallApi.FILEUPLOAD, APIS.WORKORDER.SINGLE_INDIVIDUAL_FILE_UPLOAD, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield put({
        type: ACTIONS.WORKORDER.SINGLE_INDIVIDUAL_FILE_UPLOAD,
        payload: resp.data,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* cancelWorkOrder(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.WORKORDER.ORDER_CANCEL, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

