import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { ACTIONS, APIS, STORAGE } from '../common/config/config';
function* setLoader(status) {
  yield put({
    type: status ? ACTIONS.LOADER.SHOW_LOADER : ACTIONS.LOADER.HIDE_LOADER,
  });
}
export function* loginUser(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    console.log('usersaga login call')
    const resp = yield call(CallApi.POST, APIS.USER.LOGIN, data);
    if (resp.status === 200) {
      console.log('inside user saga', resp.data)
      localStorage.setItem(STORAGE.USER_TOKEN, resp.data.data.token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: resp.data,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      console.log('inside user saga error', resp)
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* logoutUser(action) {
  try {
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.USER.LOGOUT, {}, true);

    if (resp.status >= 200 && resp.status < 300) {
      localStorage.removeItem(STORAGE.USER_TOKEN);
      yield put({
        type: ACTIONS.USER.LOGOUT,
        payload: resp,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* registrationUser(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.USER.REGISTRATION, data);
    if (resp.status === 200) {
      console.log('inside user saga', resp.data)
      // yield put({
      //   type: ACTIONS.USER.LOGIN,
      //   payload: resp.data,
      // });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      console.log('inside user saga error', resp)
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}


export function* getMe(action) {
  try {
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.USER.ME, null, true);
    if (resp.status === 200) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: resp.data,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* userActivate(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.GET, APIS.USER.MAIN_OFFICER_ACTIVATE, data);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* forgotPassword(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.USER.FORGOT_PASSWORD, data, true);
    if (resp.status === 200) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* passwordUpdate(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.USER.PASSWORD_UPDATE, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* userDetailsUpdate(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.USER.USER_INFO_UPDATE, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

