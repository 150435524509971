import React from 'react';
import { connect } from 'react-redux';

function requireNoAuth(ComposedComponent) {
  class LoginAuthentication extends React.Component {
    componentWillMount() {
      if (this.props.authenticated) {
        this.gotoPrivateRoute();
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (!prevProps.authenticated && this.props.authenticated) {
        this.gotoPrivateRoute()
      }
    }

    gotoPrivateRoute() {
      this.props.history.push('/dashboard');
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: state.user.isAuthenticated,
    };
  }

  return connect(mapStateToProps)(LoginAuthentication);
}

export default requireNoAuth;