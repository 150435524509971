import React from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { emailValidator, required } from '../../common/validators/form-validators';
import { InputField } from '../../common/form-elements/elements';
import { ACTIONS, SAGA_ACTIONS } from '../../common/config/config';

const qs = require('query-string');
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
    this.showToaster = this.showToaster.bind(this);
  }

  componentWillMount() {
  }

  showToaster(type, title, message) {
    this.toastr.add({
      type,
      title,
      position: 'top-left',
      attention: true,
      onAttentionClick: id => { },
      message,
      options: { showCloseButton: true },
      showCloseButton: true,
    });
  }

  forgotPasswordSubmit = (data) => {
    console.log('values', data);
    var params = {
      email: data.email,
      app:1,
      portal: 'customer'
    }
    console.log('params', params);

    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.FORGOT_PASSWORD,
        payload: params,
        callbackSuccess: (resp) => {
          console.log('forgotpass success==',resp);
         this.showToaster('success', 'Success', resp.data.message);
        },
        callbackError: e => {
          this.showToaster('error', 'Error', e.data.message);
          reject(new SubmissionError({ _error: e }));
        },
      });
      return resolve(true);
    });

  }

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div className="height-full">
        <div class="bg-login"></div>
        <div className="container">
          <div className="login-box">
            <div class="logo-company  mb-2">
              <img alt="Logo" src="assets/img/logo.png" />
            </div>
            <h4 className="text-center text-primary mb-4">Customer Portal</h4>
            <form name="forgotPasswordForm" onSubmit={handleSubmit(this.forgotPasswordSubmit)}>
              <div className="input-group mb-3">
                <div className="input-group-prepend"><span className="input-group-text">
                  <svg className="c-icon">
                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-envelope-open" />
                  </svg></span></div>
                {/* <input className="form-control" type="text" placeholder="Username" /> */}
                <Field component={InputField} name="email" placeholder="Email" validate={[required, emailValidator]} />
              </div>
              <div className="row">
                <div className="col-6">
                  <button className="btn btn-primary px-4" type="submit">Submit</button>
                </div>
                <div className="col-6 text-right">
                  <span> <Link to={`/login`} className="text-primary" >Login</Link></span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="footer">
          <div className="container">
            <span className="text-white">&copy; {(new Date().getFullYear())} <a className="text-white" href="https://app.landinspections.com" target="_blank">Land Inspections LLC </a>, All rights reserved. </span>
          </div>
        </footer>
      </div>
    );
  }
}

const ForgotPasswordForm = reduxForm({
  form: 'forgotPasswordForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(ForgotPassword);

const mapStateToProps = state => {

  return {

  };
};

export default connect(mapStateToProps)(ForgotPasswordForm);
