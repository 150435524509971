import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import Switch from "react-switch";
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import { required_dropdown } from '../../common/validators/form-validators';
import { SwitchField, ReactSelect } from '../../common/form-elements/elements';
import { SAGA_ACTIONS, ACTIONS } from "../../common/config/config";
class Leave extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
        this.showToaster = this.showToaster.bind(this);
  }
  showToaster(type, title, message) {
    this.toastr.add({
        type,
        title,
        position: 'top-left',
        attention: true,
        onAttentionClick: id => { },
        message,
        options: { showCloseButton: true },
        showCloseButton: true,
    });
}

  componentWillMount() {
    this.getAlltitleOfficers()
    this.getLeaveOfficers()
  }

  getAlltitleOfficers = () => {
    this.props.dispatch({
      type: SAGA_ACTIONS.TITLEOFFICERS.GET_AVAILABLE_COWORKER,
    });
  }

  getLeaveOfficers = () => {
    this.props.dispatch({
      type: SAGA_ACTIONS.TITLEOFFICERS.GET_TITLE_OFFICERS_LEAVE,
    });
  }


  leaveFormSubmit = (data) => {
    console.log('leave data', data);
    //add api call
    if (data.coworker && data.coworker.value && data.leave ) {
      var params = {
        co_worker_id: data.coworker && data.coworker.value ? data.coworker.value : null
      }
      return new Promise((resolve, reject) => {
        this.props.dispatch({
          type: SAGA_ACTIONS.TITLEOFFICERS.ADD_TITLE_OFFICERS_LEAVE,
          payload: params,
          callbackSuccess: (resp) => {
            console.log('leave resp===>', resp)
            this.showToaster('success', 'Success', resp.data.message);
          },
          callbackError: e => {
            this.showToaster('error', 'Error', e.data.message);
            reject(e);
          },
        });
        return resolve(true);
      });
    } else {

      return new Promise((resolve, reject) => {
        this.props.dispatch({
          type: SAGA_ACTIONS.TITLEOFFICERS.REMOVE_TITLE_OFFICERS_LEAVE,
          // payload: {
          //   test:'rt'
          // },
          callbackSuccess: (resp) => {
            this.showToaster('success', 'Success', resp.data.message);

          },
          callbackError: e => {
            this.showToaster('error', 'Error', e.data.message);
            reject(e);
          },
        });
        return resolve(true);
      });


    }

  }

  render() {
    console.log('this.titleofficers',this.props.titleOfficers)
    const options = [
      { value: 1, label: 'Active' },
      { value: 0, label: 'Deactive' },
    ];
    const { handleSubmit, submitting } = this.props;
    return (
      <div>
        <Sidebar />
        <div className="c-wrapper c-fixed-components">
          <Header pageTitle={"Leave"} />
          <div className="c-body">
            <main className="c-main">
              <div className="container-fluid">
                <div className="fade-in">

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header"><strong>Leave Setting</strong></div>
                        <div className="card-body">
                          <form name="leaveForm" className="forms-sample" onSubmit={handleSubmit(this.leaveFormSubmit)}>
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <div className="leave-input">
                                  <label htmlFor="name">Leave</label>
                                  <Field className="form-control" component={SwitchField} name="leave" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.props.formData && this.props.formData.leave ?
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <label htmlFor="coworker">Co worker</label>
                                    <Field component={ReactSelect} name="coworker" placeholder="Select Co-worker" options={this.props.titleOfficers} validate={[required_dropdown]} />
                                  </div>
                                </div>
                              </div> : null}
                            <button type="submit" className="btn btn-primary mr-2">Update</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>

    );
  }
}
const LeaveForm = reduxForm({
  form: 'leaveForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(Leave);

function processtitleOfficers(tolist, userDetails) {
  var tofflist = [];
  tolist.map(x => {
    if (x.id != userDetails.id) {
      var obj = { value: x.id, label: x.name }
      tofflist.push(obj)
      var obj = {};
    }

  })
  return tofflist
}

// function processInitialData(data) {

//   if (data.length && data[0].co_worker_details && data[0].co_worker_details) {
//     var obj = {
//       label: data[0].co_worker_details.name,
//       value: data[0].co_worker_details.id,
//       leave: true
//     }

//   }


// }

function initialValuesSet(data) {
  if (data.length && data[0].co_worker_details && data[0].co_worker_details) {
    var obj = {
      label: data[0].co_worker_details.name,
      value: data[0].co_worker_details.id,
    }
    var initialValue = {
      leave: true,
      coworker: obj
    };
    return initialValue;
  }

  return {}

}

const mapStateToProps = state => {
  const formDataValue =
    state.form.leaveForm && state.form.leaveForm.values
      ? state.form.leaveForm.values
      : {};
  const userDetails = state.user && state.user.user && state.user.user.data && state.user.user.data.length ? state.user.user.data[0] : null;
  const titleOfficers = state.officer && state.officer.availableTitleOfficers && state.officer.availableTitleOfficers.data && state.officer.availableTitleOfficers.data.length && userDetails ? processtitleOfficers(state.officer.availableTitleOfficers.data, userDetails) : [];
  const initialValues = state.officer && state.officer.titleOfficerLeave && state.officer.titleOfficerLeave.data && state.officer.titleOfficerLeave.data.length ? initialValuesSet(state.officer.titleOfficerLeave.data[0]) : {};
  return {
    formData: formDataValue,
    titleOfficers,
    initialValues
  };
};

export default connect(mapStateToProps)(LeaveForm);