import React from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter, HashRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import MaterialLoader from './common/loaders/material.loader';
import Login from './components/auth/Login';
import Registration from './components/auth/registration';
import Dashboard from './components/Dashboard/dashboard';
import UserProfile from './components/UserProfile/userprofile';
import TitleOfficers from './components/TitleOfficers/titleOfficers';
import WorkOrders from './components/WorkOrders/workOrders';
import Report from './components/Report/report';
import Leave from './components/Leave/leave';
import Messages from './components/Messages/messages';
import ForgotPassword from './components/forgotPassword/forgotPassword';
import FirstLogin from './components/firstLogin/firstLogin';
import requireNoAuth from './common/hoc/reqNoAuth';
import requireAuth from './common/hoc/reqAuth';
import NotFound from './components/NotFound';
import './App.css';
class App extends React.Component {
  constructor(props, context) {
    super(props, context);
  }
  componentDidMount() { }

  render() {
    return (
      <Router>
       
          <div className="App height-full">
            <MaterialLoader />
            {this.props.authenticated && (
              <div className="height-full">
                <Switch>
                  <Route exact path="/" component={requireAuth(Dashboard)} />
                  <Route path="/dashboard" component={requireAuth(Dashboard)} />
                  <Route path="/user-profile" component={requireAuth(UserProfile)} />
                  <Route path="/title-officers" component={TitleOfficers} /> 
                  <Route path="/work-orders" component={requireAuth(WorkOrders)} /> 
                  <Route path="/reports" component={requireAuth(Report)} />  
                  <Route path="/leave-settings" component={requireAuth(Leave)} />
                  <Route path="/messages" component={requireAuth(Messages)} />
                  <Route path="/login" component={requireNoAuth(Login)} />
                  <Route path="*" component={NotFound} />
                </Switch>
              </div>
            )}
            {!this.props.authenticated && (
              <Switch>
                <Route exact path="/" component={requireNoAuth(Login)} />
                <Route path="/login" component={requireNoAuth(Login)} />
                <Route path="/registration" component={requireNoAuth( Registration)} />
                <Route path="/forgot-password" component={requireNoAuth(ForgotPassword)} /> 
                <Route path="/first-login" component={requireNoAuth(FirstLogin)} />
               
                <Route
                  path="*"
                  component={() => {
                    return <Redirect to="login" />;
                  }}
                />
              </Switch>
            )}
          </div>
      
      </Router>
    );
  }
}
const mapStateToProps = state => {
  return {
    authenticated: state.user.isAuthenticated,
    // authenticated:true
  };
};
export default connect(mapStateToProps)(App);
