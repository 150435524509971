import React, { Component } from 'react';
import { connect } from "react-redux";
import Switch from "react-switch";
import ReactPaginate from 'react-paginate';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import Select from 'react-select'
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { confirmAlert } from 'react-confirm-alert';
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import { SAGA_ACTIONS, ACTIONS, customDateFormat } from "../../common/config/config";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CreateWorkRequest from './createWorkRequest';
import ViewWorkOrderRequest from './viewWorkOrderRequest';
class WorkOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenStatus: '',
            titleofficer: '',
            name: '',
            pageNumber: 0,
            isShown: false,
            isModalShow: false,
            isWorkShown: false,
            ViewWorkOrderData: null,
            showTitle: null,
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            ordernumber: '',
            setRange: '',
            checkoutStartDate: '',
            checkoutEndDate: '',
            modalTitle: '',
            workOrder: '',
        };
        this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
        this.showToaster = this.showToaster.bind(this);
    }

    showToaster(type, title, message) {
        this.toastr.add({
            type,
            title,
            position: 'top-left',
            attention: true,
            onAttentionClick: id => { },
            message,
            options: { showCloseButton: true },
            showCloseButton: true,
        });
    }
    componentWillMount() {
        this.getAllworkOrders();
        this.getAlltitleOfficers();
        // this.getCountryList();
        // this.getStateList();
    }

    getAlltitleOfficers = () => {
        this.props.dispatch({
            type: SAGA_ACTIONS.TITLEOFFICERS.TITLE_OFFICERS_LIST,
        });
    }

    getAllworkOrders = (title_officer = null, tokenstatus = null, page = 1, fromDate = null, toDate = null, order_number = null) => {
        this.props.dispatch({
            type: SAGA_ACTIONS.WORKORDER.ORDER_LIST,
            payload: {
                title_officer: title_officer ? title_officer.value : null,
                status: tokenstatus ? tokenstatus.value : null,
                page: page,
                fromDate: fromDate,
                toDate: toDate,
                order_number: order_number
            },
        });
    }

    // getCountryList = () => {
    //     this.props.dispatch({
    //         type: SAGA_ACTIONS.UTILITY.GET_COUNTRY,
    //     });
    // }

    // getStateList = () => {
    //     this.props.dispatch({
    //         type: SAGA_ACTIONS.UTILITY.GET_STATE,
    //     });
    // }


    handleEvent = (event, picker) => {
        this.setState({ setRange: moment(picker.startDate).format(customDateFormat) + ' - ' + moment(picker.endDate).format(customDateFormat), startDate: picker.startDate, endDate: picker.endDate });
        this.setState({ checkoutStartDate: moment(picker.startDate).format(customDateFormat), checkoutEndDate: moment(picker.endDate).format(customDateFormat) })
    }

    handlePageClick = e => {
        this.setState({ pageNumber: e.selected });
        // var status = this.state.tokenStatus ? this.state.tokenStatus : null;
        // this.getAllworkOrders(this.state.name, status, e.selected + 1);
        var tokenstatus = this.state.tokenStatus ? this.state.tokenStatus : null;
        var title_officer = this.state.titleofficer ? this.state.titleofficer : null;
        var fromDate = this.state.checkoutStartDate;
        var toDate = this.state.checkoutEndDate;
        var order_number = this.state.ordernumber;
        this.getAllworkOrders(title_officer, tokenstatus, e.selected + 1, fromDate, toDate, order_number);
    };

    handleChange = (tokenStatus) => {
        this.setState({ tokenStatus: tokenStatus });
    }
    handleTitleChange = (titleofficer) => {
        this.setState({ titleofficer: titleofficer });
    }
    handleOrderNumber = (e) => {
        this.setState({
            ordernumber: e.target.value
        })
    }

    filterResult = () => {
        this.setState({ pageNumber: 0 });
        var tokenstatus = this.state.tokenStatus ? this.state.tokenStatus : null;
        var title_officer = this.state.titleofficer ? this.state.titleofficer : null;
        var page = 1;
        var fromDate = this.state.checkoutStartDate;
        var toDate = this.state.checkoutEndDate;
        var order_number = this.state.ordernumber;
        this.getAllworkOrders(title_officer, tokenstatus, page, fromDate, toDate, order_number);
    }

    resetResult = () => {
        this.setState({ pageNumber: 0 });
        this.setState({
            checkoutStartDate: '', checkoutEndDate: '', startDate: moment().subtract(7, 'days'),
            endDate: moment(), setRange: '', tokenStatus: null, titleofficer: null, ordernumber: ''
        }, () => console.log('after reset=', this.state.setRange))

        this.getAllworkOrders();
    }

    createWorkOrder = () => {
        // var d = new Date();
        // var orderno = d.valueOf();
        // var orderno = Math.random().toString(36).substr(2, 9);
        var orderno = Date.now();
        //Main officers data
        if (this.props && this.props.userDetails && this.props.userDetails.user_type == 2) {
            var workOrder = {
                company: this.props && this.props.userDetails && this.props.userDetails.title_company ? this.props.userDetails.title_company : null,
                address: this.props && this.props.userDetails && this.props.userDetails.address ? this.props.userDetails.address : null,
                phone: this.props && this.props.userDetails && this.props.userDetails.phone ? this.props.userDetails.phone : null,
                // ordernumber: 'Ord-' + orderno,
                name: this.props && this.props.userDetails && this.props.userDetails && this.props.userDetails.name ? this.props.userDetails.name : null,
                state: this.props && this.props.userDetails && this.props.userDetails.state_details && this.props.userDetails.state_details.state_name ? this.props.userDetails.state_details.state_name : null,
                city: this.props && this.props.userDetails && this.props.userDetails.city_details && this.props.userDetails.city_details.city_name ? this.props.userDetails.city_details.city_name : null,
                zip: this.props && this.props.userDetails && this.props.userDetails.zipcode ? this.props.userDetails.zipcode : null,
                state_id: this.props && this.props.userDetails && this.props.userDetails.state ? this.props.userDetails.state : null,
                city_id: this.props && this.props.userDetails && this.props.userDetails.city ? this.props.userDetails.city : null,
                isUpdate: false,
            }
        }
        //title officers && this.props.userDetails.mo_details
        else {
            var workOrder = {
                company: this.props && this.props.userDetails && this.props.userDetails.mo_details && this.props.userDetails.mo_details.title_company ? this.props.userDetails.mo_details.title_company : null,
                address: this.props && this.props.userDetails && this.props.userDetails.mo_details && this.props.userDetails.mo_details.address ? this.props.userDetails.mo_details.address : null,
                phone: this.props && this.props.userDetails && this.props.userDetails.mo_details && this.props.userDetails.mo_details.phone ? this.props.userDetails.mo_details.phone : null,
                // ordernumber: 'Ord-' + orderno,
                name: this.props && this.props.userDetails && this.props.userDetails.name ? this.props.userDetails.name : null,

                state: this.props && this.props.userDetails && this.props.userDetails.mo_details && this.props.userDetails.mo_details.state_details && this.props.userDetails.mo_details.state_details.state_name ? this.props.userDetails.mo_details.state_details.state_name : null,
                city: this.props && this.props.userDetails && this.props.userDetails.mo_details && this.props.userDetails.mo_details.city_details && this.props.userDetails.mo_details.city_details.city_name ? this.props.userDetails.mo_details.city_details.city_name : null,
                zip: this.props && this.props.userDetails && this.props.userDetails.mo_details && this.props.userDetails.mo_details.zipcode ? this.props.userDetails.mo_details.zipcode : null,
                state_id: this.props && this.props.userDetails && this.props.userDetails.mo_details && this.props.userDetails.mo_details.state ? this.props.userDetails.mo_details.state : null,
                city_id: this.props && this.props.userDetails && this.props.userDetails.mo_details && this.props.userDetails.mo_details.city ? this.props.userDetails.mo_details.city : null,
                isUpdate: false,
            }
        }

        this.setState({ isModalShow: true, modalTitle: 'Create Work Request', workOrder: workOrder });
    }

    CloseWorkOrder = () => {
        this.setState({ isModalShow: false, workOrder: null });
    }

    viewWorkOrderForm = (data) => {
        this.setState({ isWorkShown: true, showTitle: 'View Work Order', ViewWorkOrderData: data });
    }
    cancelWorkOrderForm = (id) => {
        console.log(id)
        // this.props.dispatch({
        //     type: SAGA_ACTIONS.WORKORDER.ORDER_CANCEL,
        //     payload: {
        //         id: id,
        //         status: 5
        //     },
        // });
        return new Promise((resolve, reject) => {
            this.props.dispatch({
                type: SAGA_ACTIONS.WORKORDER.ORDER_CANCEL,
                payload: {
                    id: id,
                    status: 5
                },
                callbackSuccess: resp => {
                    this.showToaster('success', 'Success', resp.data.message);

                    confirmAlert({
                        title: 'Success',
                        message: 'YOUR WORK ORDER HAS BEEN CANCELLED SUCCESSFULLY.',
                        buttons: [
                            {
                                label: 'OKAY',
                                onClick: function () {
                                    window.location.reload()
                                }
                            }

                        ]
                    });


                },
                callbackError: e => {
                    // console.log('error',e.data)
                    this.showToaster('error', 'Error', 'error');
                    reject(e);
                }
            });
            return resolve(true);
        });
    }
    CloseViewModal = () => {
        this.setState({ isWorkShown: false, ViewWorkOrderData: null });
    }
    getStatus = (status) => {
        var text = '';
        switch (status) {
            case 1:
                text = <span className='badge badge-secondary'>Created</span>;
                break;
            case 2:
                text = <span className='badge badge-warning'>Assigned</span>;
                break;
            case 3:
                text = <span className='badge badge-warning'>In progress</span>;
                break;
            case 4:
                text = <span className='badge badge-success'>Published</span>;
                break;
            case 5:
                text = <span className='badge badge-danger'>Cancelled</span>;
                break;
            default:
                text = <span className='badge badge-danger'>Unknown</span>;
        }
        return (text)
    }
    render() {
        const options = [
            { value: 1, label: 'Created' },
            { value: 2, label: 'Assigned' },
            { value: 3, label: 'In progress' },
            { value: 4, label: 'Published' },
        ];
        const { userDetails } = this.props;
        return (
            <div>
                <Sidebar />
                <div className="c-wrapper c-fixed-components">
                    <Header pageTitle={"Work Orders"} />
                    <div className="c-body">
                        <main className="c-main">
                            <div className="container-fluid">
                                <div className="fade-in">
                                    <div className="row vertical-center">
                                        {userDetails && userDetails.user_type == 2 ?
                                            <div className="col-2">
                                                <div className="form-group form-focus">
                                                    <label className="focus-label">Title Officers</label>
                                                    <div className="cal-icon react-cus-dropdown">
                                                        <Select
                                                            value={this.state.titleofficer}
                                                            onChange={this.handleTitleChange}
                                                            classNamePrefix="my-menu"
                                                            options={this.props.titleOfficers}
                                                        // options={options}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null}
                                        <div className="col-2">
                                            <div className="form-group form-focus">
                                                <label className="focus-label">Date Range</label>
                                                <div className="cal-icon">
                                                    <DateRangePicker
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        onEvent={this.handleEvent}
                                                        maxDate={moment()}
                                                        ranges={{
                                                            '7 Days': [moment().subtract(7, 'days'), moment()],
                                                        }
                                                        }

                                                    >
                                                        <input type="text" className="form-control floating w-100" value={this.state.setRange} />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group form-focus">
                                                <label className="focus-label">Order Number</label>
                                                <input type="text" id="ordernumber" className="form-control floating" name="ordernumber" value={this.state.ordernumber} onChange={this.handleOrderNumber} />
                                            </div>
                                        </div>

                                        <div className="col-2">
                                            <div className="form-group form-focus">
                                                <label className="focus-label">Status</label>
                                                <div className="cal-icon react-cus-dropdown">
                                                    <Select
                                                        value={this.state.tokenStatus}
                                                        onChange={this.handleChange}
                                                        classNamePrefix="my-menu"
                                                        options={options}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 mt-adjust">
                                            <button type="button" className="btn btn-success btn-block" onClick={this.filterResult}> Search </button>
                                        </div>
                                        <div className="col-2 mt-adjust">
                                            <button type="button" className="btn btn-warning btn-block text-white" onClick={this.resetResult}> Reset </button>
                                        </div>


                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <span className="left-side"><i className="fa fa-align-justify" /> Work Orders List</span>
                                                    {/* <span className="create-button right-side" onClick={this.createWorkOrder}>Create Work Order</span> */}
                                                    <button type="button" className="btn btn-outline-info right-side" onClick={this.createWorkOrder}> Create Work Order </button>
                                                </div>
                                                <div className="card-body">
                                                    <table className="table table-responsive-sm table-bordered table-striped table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th>Title Officers</th>
                                                                <th>Report State</th>
                                                                <th>Submitted On</th>
                                                                <th>Order Number</th>
                                                                <th>Status</th>
                                                                <th>View Details</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.props && this.props.workOrderList && this.props.workOrderList.length ? (
                                                                this.props.workOrderList.map(workOrder => {
                                                                    return (
                                                                        <tr key={workOrder.id}>
                                                                            <td>
                                                                                {workOrder && workOrder.title_officer_details && workOrder.title_officer_details.name ? workOrder.title_officer_details.name : null}
                                                                            </td>
                                                                            <td>
                                                                                {workOrder.reportState}
                                                                            </td>
                                                                            <td>
                                                                                {moment(
                                                                                    `${workOrder.created_at}`
                                                                                ).format(customDateFormat)}
                                                                            </td>
                                                                            <td>
                                                                                {workOrder.order_number ? workOrder.order_number : null}
                                                                            </td>
                                                                            <td>
                                                                                {workOrder.status ? this.getStatus(workOrder.status) : null}
                                                                            </td>
                                                                            <td className="view files">
                                                                                <span onClick={() => this.viewWorkOrderForm(workOrder)}><i class="fa fa-eye" aria-hidden="true"></i></span>&nbsp;
                                                                                {workOrder.status != 5 ? (
                                                                                    <span onClick={() => this.cancelWorkOrderForm(workOrder.id)}><i class="fa fa-trash" aria-hidden="true"></i></span>
                                                                                ) : (
                                                                                    <p></p>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={70} className="no-record">
                                                                        no record
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    <nav>
                                                        {/* <ul className="pagination">
                                                            <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                                                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">4</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                        </ul> */}
                                                        {this.props && this.props.totalNoOfWorkOrder ?
                                                            <div style={{ textAlign: 'center' }}>
                                                                <ReactPaginate
                                                                    previousLabel={'Prev'}
                                                                    nextLabel={'Next'}
                                                                    breakLabel="..."
                                                                    breakClassName="break-me"
                                                                    pageCount={Math.ceil(this.props.totalNoOfWorkOrder / 10)}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={this.handlePageClick}
                                                                    containerClassName="pagination"
                                                                    subContainerClassName="pages pagination"
                                                                    activeClassName="active"
                                                                    pageClassName="page-item"
                                                                    pageLinkClassName="page-link"
                                                                    previousClassName="page-item"
                                                                    nextClassName="page-item"
                                                                    previousLinkClassName="page-link"
                                                                    nextLinkClassName="page-link"
                                                                    forcePage={this.state.pageNumber}
                                                                />
                                                            </div> : null}
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /.col*/}
                                    </div>
                                    {/* /.row*/}
                                </div>
                            </div>
                        </main>
                        <Footer />
                    </div>
                </div>
                <CreateWorkRequest
                    CloseWorkOrder={this.CloseWorkOrder}
                    isShown={this.state.isModalShow}
                    modalTitle={this.state.modalTitle}
                    getAllworkOrders={this.getAllworkOrders}
                    workOrder={this.state.workOrder}
                    // countryList={this.props.countryList}
                    // stateList={this.props.stateList}
                    titleOfficers={this.props.titleOfficers}
                    userDetails={this.props.userDetails}
                />
                <ViewWorkOrderRequest
                    CloseViewModal={this.CloseViewModal}
                    isWorkShown={this.state.isWorkShown}
                    modalTitle={this.state.showTitle}
                    workOrderData={this.state.ViewWorkOrderData}
                />
            </div>
        );
    }
}

// function processCountry(clist) {
//     var country = [];
//     clist.map(x => {
//         var obj = { value: x.id, label: x.country_name }
//         country.push(obj)
//         var obj = {};
//     })
//     return country
// }

// function processState(slist) {
//     var state = [];
//     slist.map(x => {
//         var obj = { value: x.id, label: x.state_name }
//         state.push(obj)
//         var obj = {};
//     })
//     return state
// }

function processtitleOfficers(tolist, userDetails) {

    if (userDetails.user_type != 3) {
        var tofflist = [{ value: userDetails.id, label: 'Myself' }];
    } else {
        var tofflist = [];
    }

    tolist.map(x => {
        var obj = { value: x.id, label: x.name }
        tofflist.push(obj)
        var obj = {};
    })
    return tofflist
}

const mapStateToProps = state => {
    const userDetails = state.user && state.user.user && state.user.user.data && state.user.user.data.length ? state.user.user.data[0] : null;
    // const countryList = state.utility && state.utility.countryList && state.utility.countryList.data && state.utility.countryList.data.length ? processCountry(state.utility.countryList.data[0]) : [];
    // const stateList = state.utility && state.utility.stateList && state.utility.stateList.data && state.utility.stateList.data.length ? processState(state.utility.stateList.data[0]) : [];
    const titleOfficers = userDetails && state.officer && state.officer.titleOfficersList && state.officer.titleOfficersList.data && state.officer.titleOfficersList.data.length ? processtitleOfficers(state.officer.titleOfficersList.data[0], userDetails) : [];
    const workOrderList = state.workorder && state.workorder.workOrderList && state.workorder.workOrderList.data && state.workorder.workOrderList.data.length && state.workorder.workOrderList.data[0] && state.workorder.workOrderList.data[0].data ? state.workorder.workOrderList.data[0].data : [];
    const totalNoOfWorkOrder = state.workorder && state.workorder.workOrderList && state.workorder.workOrderList.data && state.workorder.workOrderList.data.length && state.workorder.workOrderList.data[0] && state.workorder.workOrderList.data[0].data ? state.workorder.workOrderList.data[0].total : 0;
    return {
        userDetails,
        // countryList,
        // stateList,
        titleOfficers,
        workOrderList,
        totalNoOfWorkOrder
    };
};

export default connect(mapStateToProps)(WorkOrders);