import React from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { emailValidator, required } from '../../common/validators/form-validators';
import { InputField } from '../../common/form-elements/elements';
import { ACTIONS, SAGA_ACTIONS } from '../../common/config/config';

const qs = require('query-string');
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
    this.showToaster = this.showToaster.bind(this);
  }

  componentWillMount() {

    if (this.props.location && this.props.location.search) {
      let search = qs.parse(this.props.location.search)
      console.log('token=====', search.token);
      if (search && search.token) {
        var params = {
          token: search.token,
          portal: 'customer'
        }
        console.log('params', params);
        return new Promise((resolve, reject) => {
          this.props.dispatch({
            type: SAGA_ACTIONS.USER.MAIN_OFFICER_ACTIVATE,
            payload: params,
            callbackSuccess: (resp) => {
              this.props.history.push('/login');
              this.showToaster('success', 'Success', resp.data.message);
            },
            callbackError: e => {
              this.showToaster('error', 'Error', e.data.message);
              reject(new SubmissionError({ _error: e }));
            },
          });
          return resolve(true);
        });
      }
      else {
        this.showToaster('error', 'Something Wrong', 'Contact to admin');
      }

    }

  }

  showToaster(type, title, message) {
    this.toastr.add({
      type,
      title,
      position: 'top-left',
      attention: true,
      onAttentionClick: id => { },
      message,
      options: { showCloseButton: true },
      showCloseButton: true,
    });
  }

  loginFormSubmit = (data) => {
    console.log('values', data);
    var params = {
      email: data.email,
      password: data.password,
      portal: 'customer'
    }
    console.log('params', params);

    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.LOGIN,
        payload: params,
        callbackSuccess: (resp) => {
          console.log('resp', resp)
          //  this.showToaster('success', 'Success', resp.data.message);
          this.props.dispatch({
            type: SAGA_ACTIONS.USER.ME,
            // payload: params,
            callbackSuccess: (resp1) => {
              // this.showToaster('success', 'Success', 'Login Successfully');
              this.props.history.push('/dashboard');
            },
            callbackError: er => {
              this.showToaster('error', 'Error', er.data.msg);
              reject(new SubmissionError({ _error: er }));
            },
          });

        },
        callbackError: e => {
          console.log('error', e)
          this.showToaster('error', 'Error', e.data.message);
          reject(new SubmissionError({ _error: e }));
        },
      });
      return resolve(true);
    });

  }

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div className="height-full">
        <div className="bg-login"></div>
        <div className="container">
          <div className="login-box">
            <div className="logo-company  mb-2">
              <img alt="Logo" src="assets/img/logo.png" />
            </div>
            <h4 className="text-center text-primary mb-4">Customer Portal</h4>
            <form name="loginForm" onSubmit={handleSubmit(this.loginFormSubmit)}>
              <div className="input-group mb-4">
                <div className="input-group-prepend"><span className="input-group-text">
                  <svg className="c-icon">
                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-envelope-open" />
                  </svg></span></div>
                {/* <input className="form-control" type="text" placeholder="Username" /> */}
                <Field component={InputField} name="email" placeholder="Email" validate={[required, emailValidator]} />

              </div>
              <div className="input-group mb-4">
                <div className="input-group-prepend"><span className="input-group-text">
                  <svg className="c-icon">
                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-lock-locked" />
                  </svg></span></div>
                {/* <input className="form-control" type="password" placeholder="Password" /> */}
                <Field component={InputField} type="password" placeholder="Password" name="password" validate={[required]} />
              </div>
              <div className="row">
                <div className="col-4">
                  <button className="btn btn-primary px-4" type="submit">Login</button>
                </div>
                <div className="col-8 text-right">
                  <p className="mb-0"> <Link className="text-primary" to={`/forgot-password`} >Forgot password?</Link></p>

                  <p className="mb-0"> No account? <Link className="text-primary" to={`/registration`} >Register Now!</Link></p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="footer">
          <div className="container">
            <span className="text-white">&copy; {(new Date().getFullYear())} <a className="text-white" href="https://app.landinspections.com" target="_blank">Land Inspections LLC </a>, All rights reserved. </span>
          </div>
        </footer>
      </div>
    );
  }
}

const CreateLoginForm = reduxForm({
  form: 'loginForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(Login);

const mapStateToProps = state => {

  return {

  };
};

export default connect(mapStateToProps)(CreateLoginForm);
