import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import { SAGA_ACTIONS } from "../../common/config/config";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  componentWillMount() {
    this.getDashboardCount();
  }

  getDashboardCount = () => {
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: SAGA_ACTIONS.UTILITY.DASHBOARD_COUNT,
        callbackSuccess: resp => {
        },
        callbackError: e => {
          reject(e);
        }
      });
      return resolve(true);
    });
  }

  render() {
    const { dashboardCount, userDetails } = this.props
    const toggle = () => this.setState({
      ...this.state,
      modal: !this.state.modal
    });
    return (
      <div>
        <Sidebar />
        <div className="c-wrapper c-fixed-components">
          <Header pageTitle={"Dashboard"} />
          <div className="c-body">
            <main className="c-main">
              <div className="container-fluid">
                <div className="fade-in">
                  <div className='row'>
                    {/* <div style={{
                      border: "dotted 1px red",
                      padding: "10px",
                      borderRadius: "10px",
                      background: "#ddd",
                    }} className='col-md-10 m-auto'>
                      <p>
                        As you are no doubt aware, fuel prices are at all-time high levels. Land Inspections has resisted raising prices as long as possible, but due to the critical nature of the current situation, we can no longer continue to absorb the increased cost.</p>
                      <p> Therefore, effective immediately, we will be increasing our base prices by $10. You can find our current fee schedule on our website at <a href="https://www.landinspections.com">www.landinspections.com</a>.</p>
                      <p>We appreciate your understanding of the dilemma we face as fuel costs increase. We hope that by sharing this burden we can maintain the high quality service you have come to expect from Land Inspections.
                      </p>
                    </div> */}
                  </div>
                  <div className="row">
                    {userDetails && userDetails.user_type == 2 ?
                      <div className="col-sm-6 col-lg-4">
                        <NavLink className="nav-link" to="/title-officers">
                          <div className="dash-box light-green-bg">
                            <h4>Title Officers</h4>
                            <div className="dash-value">{dashboardCount && dashboardCount.titleofficers_count ? dashboardCount.titleofficers_count : 0}</div>
                          </div>
                        </NavLink>
                        {/* <div className="card text-white bg-gradient-primary">
                          <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                            <div>
                              <div className="text-value-lg">{dashboardCount && dashboardCount.titleofficers_count ? dashboardCount.titleofficers_count : 0}</div>
                              <div>Title Officers</div>
                            </div>
                            
                          </div>

                        </div> */}

                      </div> : null}
                    {/* /.col*/}
                    <div className={userDetails && userDetails.user_type == 2 ? "col-sm-6 col-lg-4" : "col-sm-6 col-lg-6"}>
                      <NavLink className="nav-link" to="/work-orders">
                        <div className="dash-box">
                          <h4>Work Orders</h4>
                          <div className="dash-value">{dashboardCount && dashboardCount.workorder_count ? dashboardCount.workorder_count : 0}</div>
                        </div>
                      </NavLink>
                    </div>
                    {/* /.col*/}
                    <div className={userDetails && userDetails.user_type == 2 ? "col-sm-6 col-lg-4" : "col-sm-6 col-lg-6"}>
                      <NavLink className="nav-link" to="/reports">
                        <div className="dash-box light-green-bg">
                          <h4>Reports</h4>
                          <div className="dash-value">{dashboardCount && dashboardCount.report ? dashboardCount.report : 0}</div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                isOpen={this.state.modal}
                toggle={toggle}
                backdrop={'static'}
                size={'md'}
                fade={false}
                keyboard={false}
                className={'border-left-modal'}
              >
                <ModalHeader toggle={toggle}>Announcement</ModalHeader>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className='col-md-12'>
                        <p>No new announcement for now</p>
                        {/* <p>
                          As you are no doubt aware, fuel prices are at all-time high levels. Land Inspections has resisted raising prices as long as possible, but due to the critical nature of the current situation, we can no longer continue to absorb the increased cost.</p>
                        <p> Therefore, effective immediately, we will be increasing our base prices by $10. You can find our current fee schedule on our website at <a href="https://www.landinspections.com">www.landinspections.com</a>.</p>
                        <p>We appreciate your understanding of the dilemma we face as fuel costs increase. We hope that by sharing this burden we can maintain the high quality service you have come to expect from Land Inspections.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </main>
            <Footer />
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = state => {

  const dashboardCount = state.utility && state.utility.dashboardCount && state.utility.dashboardCount.data ? state.utility.dashboardCount.data : {};
  const userDetails = state.user && state.user.user && state.user.user.data && state.user.user.data.length ? state.user.user.data[0] : null;
  return {
    dashboardCount,
    userDetails
  };
};

export default connect(mapStateToProps)(Dashboard);