import { ACTIONS } from '../../common/config/config';

const initialState = {
  workOrderList: null,
};

const workOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.WORKORDER.ORDER_LIST:
      return {
        ...state,
        workOrderList: action.payload,
      };
    default:
      return state;
  }
};

export default workOrderReducer;
