import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { ACTIONS, APIS, STORAGE } from '../common/config/config';
function* setLoader(status) {
  yield put({
    type: status ? ACTIONS.LOADER.SHOW_LOADER : ACTIONS.LOADER.HIDE_LOADER,
  });
}


export function* titleOfficersCreate(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.TITLEOFFICERS.CREATE_TITLE_OFFICERS, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* titleOfficerActivate(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.TITLEOFFICERS.TITLE_OFFICER_ACTIVATE, data);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* titleOfficersList(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.GET, APIS.TITLEOFFICERS.TITLE_OFFICERS_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield put({
        type: ACTIONS.TITLEOFFICERS.TITLE_OFFICERS_LIST,
        payload: resp.data,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* titleOfficersListWithAllDetails(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.GET, APIS.TITLEOFFICERS.TITLE_OFFICERS_LIST_WITH_ALL_DETAILS, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield put({
        type: ACTIONS.TITLEOFFICERS.TITLE_OFFICERS_LIST_WITH_ALL_DETAILS,
        payload: resp.data,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}


export function* titleOfficersUpdate(action) {
  try {
    yield setLoader(true);
    const data = action.payload.data;
    const resp = yield call(CallApi.POST, APIS.TITLEOFFICERS.TITLE_OFFICERS_UPDATE, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* getTitleOfficersLeave(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.GET, APIS.TITLEOFFICERS.GET_TITLE_OFFICERS_LEAVE, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield put({
        type: ACTIONS.TITLEOFFICERS.GET_TITLE_OFFICERS_LEAVE,
        payload: resp.data,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}
export function* createTitleOfficersLeave(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.TITLEOFFICERS.ADD_TITLE_OFFICERS_LEAVE, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* deleteTitleOfficersLeave(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.TITLEOFFICERS.REMOVE_TITLE_OFFICERS_LEAVE, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* getAvailableCoWorkers(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.GET, APIS.TITLEOFFICERS.GET_AVAILABLE_COWORKER, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield put({
        type: ACTIONS.TITLEOFFICERS.GET_AVAILABLE_COWORKER,
        payload: resp.data,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}