export const BASE_URL = 'https://backend.landinspections.com/api/v1/'
// export const BASE_URL = 'https://heather-backend.glohtesting.com/api/v1/'
// export const BASE_URL = 'http://127.0.0.1:8000/api/v1/'

// export const STORAGE = {
//   AUTH_TOKEN: 'auth_token',
// };

export const STORAGE = {
    TOKEN_KEY: 'token-key',
    USER_TOKEN: 'user-token'
};
export const customDateFormat = 'DD-MMM-YYYY';
export const ErrorMsg = (e) => {
    if (e && e.data.errors && e.data.errors.length) {
        var err = e.data.errors;
        var errArrays = Object.values(err);
        var msg = '';
        errArrays.map(x => {

            msg = msg + ' ' + x;
        })
        return msg;
    } else if (typeof (e.data.errors) == 'object') {
        var keys = Object.keys(e.data.errors);
        var values = Object.values(e.data.errors);
        var msg = '';
        values.map((x, i) => {
            msg = msg + keys[i].replace(/_/g, ' ') + ' - ' + x;

        })

        return msg;
    }
    else {
        return e.data.message
    }
}

export const APIS = {
    USER: {
        LOGIN: `${BASE_URL}login`,
        REGISTRATION: `${BASE_URL}main/officer/register`,
        LOGOUT: `${BASE_URL}logout`,
        ME: `${BASE_URL}my/details`,
        MAIN_OFFICER_ACTIVATE: `${BASE_URL}activate/account`,
        FORGOT_PASSWORD: `${BASE_URL}get-reset-token`,
        PASSWORD_UPDATE: `${BASE_URL}my/password/update`,
        USER_INFO_UPDATE: `${BASE_URL}my/account/update`,
    },
    WORKORDER: {
        UPLOAD_FILE: `${BASE_URL}upload/files`,
        ORDER_LIST: `${BASE_URL}order/list`,
        ORDER_SAVE: `${BASE_URL}save/order`,
        ORDER_CANCEL: `${BASE_URL}update/order`,
        SINGLE_INDIVIDUAL_FILE_UPLOAD: `${BASE_URL}order/single/upload`,
    },
    TITLEOFFICERS: {
        CREATE_TITLE_OFFICERS: `${BASE_URL}title/officer/register`,
        TITLE_OFFICER_ACTIVATE: `${BASE_URL}user-password-update`,
        TITLE_OFFICERS_LIST: `${BASE_URL}title/officer/list`,
        TITLE_OFFICERS_UPDATE: `${BASE_URL}title/officer/update`,
        TITLE_OFFICERS_LIST_WITH_ALL_DETAILS: `${BASE_URL}title/officer/list`,
        GET_TITLE_OFFICERS_LEAVE: `${BASE_URL}leave/get`,
        ADD_TITLE_OFFICERS_LEAVE: `${BASE_URL}leave/add`,
        REMOVE_TITLE_OFFICERS_LEAVE: `${BASE_URL}leave/remove`,
        GET_AVAILABLE_COWORKER: `${BASE_URL}get/available/coworker`,
    },
    UTILITY: {
        GET_COUNTRY: `${BASE_URL}country/list`,
        GET_STATE: `${BASE_URL}state/list`,
        GET_CITY: `${BASE_URL}city/list`,
        DASHBOARD_COUNT: `${BASE_URL}my/dashboard`,
        GET_ALL_REPORT: `${BASE_URL}order/reports`,
        REPORT_VIEW_OR_DOWNLOAD: `${BASE_URL}export/pdf`,
    },
    MESSAGES: {
        GET_FRIEND_LIST: `${BASE_URL}my/chat/list`,
        GET_MSG_WITH_PARTICULAR_FRND: `${BASE_URL}get/chat/details`,
        SAVE_OR_SEND_MSG: `${BASE_URL}send/chat`,
        GET_ALL_CHAT_COUNT: `${BASE_URL}get/all/chat/count`,
        FILE_SEND_OVER_MESSAGE: `${BASE_URL}send/chat/file`,
    }

}

export const ACTIONS = {
    LOADER: {
        SHOW_LOADER: 'SHOW_MATERIAL_LOADER',
        HIDE_LOADER: 'HIDE_MATERIAL_LOADER',
    },
    USER: {
        LOGIN: 'ACTION/USER/LOGIN',
        LOGOUT: 'ACTION/USER/LOGOUT',
        ME: 'ACTION/USER/ME',
    },
    WORKORDER: {
        UPLOAD_FILE: 'ACTION/WORKORDER/UPLOAD_FILE',
        ORDER_LIST: 'ACTION/WORKORDER/ORDER_LIST',
        ORDER_CANCEL: 'ACTION/WORKORDER/ORDER_CANCEL',
        SINGLE_INDIVIDUAL_FILE_UPLOAD: 'ACTION/WORKORDER/SINGLE_INDIVIDUAL_FILE_UPLOAD'
    },
    TITLEOFFICERS: {
        TITLE_OFFICERS_LIST: 'ACTION/TITLEOFFICERS/TITLE_OFFICERS_LIST',
        TITLE_OFFICERS_LIST_WITH_ALL_DETAILS: 'ACTION/TITLEOFFICERS/TITLE_OFFICERS_LIST_WITH_ALL_DETAILS',
        GET_TITLE_OFFICERS_LEAVE: 'ACTION/TITLEOFFICERS/GET_TITLE_OFFICERS_LEAVE',
        GET_AVAILABLE_COWORKER: 'ACTION/TITLEOFFICERS/GET_AVAILABLE_COWORKER',
    },
    UTILITY: {
        GET_COUNTRY: 'ACTION/UTILITY/GET_COUNTRY',
        GET_STATE: 'ACTION/UTILITY/GET_STATE',
        GET_CITY: 'ACTION/UTILITY/GET_CITY',
        DASHBOARD_COUNT: 'ACTION/UTILITY/DASHBOARD_COUNT',
        GET_ALL_REPORT: 'ACTION/UTILITY/GET_ALL_REPORT',
    },
    MESSAGES: {
        GET_FRIEND_LIST: 'ACTION/MESSAGES/GET_FRIEND_LIST',
        GET_MSG_WITH_PARTICULAR_FRND: 'ACTION/MESSAGES/GET_MSG_WITH_PARTICULAR_FRND',
        EMPTY_MSG_DETAILS_WITH_PARTICULAR_FRND:'ACTION/MESSAGES/EMPTY_MSG_DETAILS_WITH_PARTICULAR_FRND',
        SELECTED_CHAT_FRIEND: 'ACTION/MESSAGES/SELECTED_CHAT_FRIEND',
        GET_ALL_CHAT_COUNT:'ACTION/MESSAGES/GET_ALL_CHAT_COUNT',
    }

}

export const SAGA_ACTIONS = {
    USER: {
        LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
        REGISTRATION: 'SAGA_ACTIONS/USER/REGISTRATION',
        LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
        ME: 'SAGA_ACTIONS/USER/ME',
        MAIN_OFFICER_ACTIVATE: 'SAGA_ACTIONS/USER/MAIN_OFFICER_ACTIVATE',
        FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
        PASSWORD_UPDATE: 'SAGA_ACTIONS/USER/PASSWORD_UPDATE',
        USER_INFO_UPDATE: 'SAGA_ACTIONS/USER/USER_INFO_UPDATE',
    },
    WORKORDER: {
        UPLOAD_FILE: 'SAGA_ACTIONS/WORKORDER/UPLOAD_FILE',
        ORDER_LIST: 'SAGA_ACTIONS/WORKORDER/ORDER_LIST',
        ORDER_SAVE: 'SAGA_ACTIONS/WORKORDER/ORDER_SAVE',
        ORDER_CANCEL: 'SAGA_ACTIONS/WORKORDER/ORDER_CANCEL',
        SINGLE_INDIVIDUAL_FILE_UPLOAD: 'SAGA_ACTIONS/WORKORDER/SINGLE_INDIVIDUAL_FILE_UPLOAD'
    },
    TITLEOFFICERS: {
        CREATE_TITLE_OFFICERS: 'SAGA_ACTIONS/TITLEOFFICERS/CREATE_TITLE_OFFICERS',
        TITLE_OFFICER_ACTIVATE: 'SAGA_ACTIONS/TITLEOFFICERS/TITLE_OFFICER_ACTIVATE',
        TITLE_OFFICERS_LIST: 'SAGA_ACTIONS/TITLEOFFICERS/TITLE_OFFICERS_LIST',
        TITLE_OFFICERS_UPDATE: 'SAGA_ACTIONS/TITLEOFFICERS/TITLE_OFFICERS_UPDATE',
        TITLE_OFFICERS_LIST_WITH_ALL_DETAILS: 'SAGA_ACTIONS/TITLEOFFICERS/TITLE_OFFICERS_LIST_WITH_ALL_DETAILS',
        GET_TITLE_OFFICERS_LEAVE: 'SAGA_ACTIONS/TITLEOFFICERS/GET_TITLE_OFFICERS_LEAVE',
        ADD_TITLE_OFFICERS_LEAVE: 'SAGA_ACTIONS/TITLEOFFICERS/ADD_TITLE_OFFICERS_LEAVE',
        REMOVE_TITLE_OFFICERS_LEAVE: 'SAGA_ACTIONS/TITLEOFFICERS/REMOVE_TITLE_OFFICERS_LEAVE',
        GET_AVAILABLE_COWORKER: 'SAGA_ACTIONS/TITLEOFFICERS/GET_AVAILABLE_COWORKER',
    },
    UTILITY: {
        GET_COUNTRY: 'SAGA_ACTIONS/UTILITY/GET_COUNTRY',
        GET_STATE: 'SAGA_ACTIONS/UTILITY/GET_STATE',
        GET_CITY: 'SAGA_ACTIONS/UTILITY/GET_CITY',
        DASHBOARD_COUNT: 'SAGA_ACTIONS/UTILITY/DASHBOARD_COUNT',
        GET_ALL_REPORT: 'SAGA_ACTIONS/UTILITY/GET_ALL_REPORT',
        REPORT_VIEW_OR_DOWNLOAD: 'SAGA_ACTIONS/UTILITY/REPORT_VIEW_OR_DOWNLOAD',
    },
    MESSAGES: {
        GET_FRIEND_LIST: 'SAGA_ACTIONS/MESSAGES/GET_FRIEND_LIST',
        GET_MSG_WITH_PARTICULAR_FRND: 'SAGA_ACTIONS/MESSAGES/GET_MSG_WITH_PARTICULAR_FRND',
        SAVE_OR_SEND_MSG: 'SAGA_ACTIONS/MESSAGES/SAVE_OR_SEND_MSG',
        GET_ALL_CHAT_COUNT:'SAGA_ACTIONS/MESSAGES/GET_ALL_CHAT_COUNT',
        FILE_SEND_OVER_MESSAGE: 'SAGA_ACTIONS/MESSAGES/FILE_SEND_OVER_MESSAGE',
    }
}