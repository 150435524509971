import React, { Component } from 'react';
import { reduxForm, Field, change, FieldArray } from 'redux-form';

export class FieldFileArrayInput2 extends React.Component {
    constructor(props) {
        super(props)
        // this.onChange = this.onChange.bind(this)
        this.state = {
            inputFields: [{ cfile: '' }],

        };

    }
    handleChangeInput = (index, event) => {
        console.log('raw', event.target.files[0])
        const values = [...this.state.inputFields];
        values[index][event.target.name] = event.target.files[0];
        this.setState({ inputFields: values }, () => console.log('all files', this.state.inputFields));

        // console.log('all files',this.state.inputFields)
    }

    handleAddFields = () => {
        // setInputFields([...inputFields, { firstName: '', lastName: '' }])
        this.setState(prevState => ({ inputFields: [...prevState.inputFields, { cfile: '' }] }));
    }

    handleRemoveFields = (index) => {
        const values = [...this.state.inputFields];
        values.splice(index, 1);
        this.setState({ inputFields: values.map(x => Object.assign(x)) }, () => console.log('all files', this.state.inputFields));
    }

    render() {
        // const { input: { value } } = this.props
        const { input: { value, onChange, name }, label, required, meta, } = this.props
        const { inputFields } = this.state
        // const {input,label, required, meta, } = this.props  //whatever props you send to the component from redux-form Field
        return (
            <div><label>{label}</label>
                <div>
                    {
                        inputFields.map((field, i) => {
                            return (
                                <div key={i} id={'cusid' + i}>
                                    <input
                                        name="cfile"
                                        type='file'
                                        id={'cfile' + i}
                                        // accept='.jpg, .png, .jpeg'
                                        onChange={(event) => this.handleChangeInput(i, event)}
                                        value={null}
                                    // onChange={(e) => this.props.input.onChange(e.target.files[0])}
                                    />
                                    <button type="button" onClick={() => this.handleRemoveFields(i)}>
                                        Remove
                                    </button>

                                </div>
                            )
                        })
                    }

                    <button type="button" onClick={() => this.handleAddFields()}>
                        Add
      </button>
                </div>
            </div>
        )
    }
}