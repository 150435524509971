import React from 'react';

class CheckboxGroup extends React.Component {
    setColorClass = (optionId) => {
        if (this.props && this.props.formDataValue && this.props.formDataValue.length) {
            if (optionId == 2) {
                var rush = this.props.formDataValue.indexOf(optionId);
                if (rush > -1) {                  
                    return "rush typecheckbox"
                }
            }
            if (optionId == 3) {
                var surush = this.props.formDataValue.indexOf(optionId);
                if (surush > -1) {                   
                    return "super-rush"
                }
            }


        }
    }

    checkboxGroup() {
        let { label, required, options, input, meta: { touched, error, warning } } = this.props;
        console.log('incomming props checkbox', this.props);
        return options.map((option, index) => {
            return (
                <li className={this.setColorClass(option.id)} key={index}>
                    <label>
                        <input type="checkbox"
                            name={`${input.name}[${index}]`}
                            value={option.id}
                            checked={input.value.indexOf(option.id) !== -1}
                            onChange={(event) => {
                                const newValue = [...input.value];
                                if (event.target.checked) {
                                    newValue.push(option.id);
                                } else {
                                    newValue.splice(newValue.indexOf(option.id), 1);
                                }

                                return input.onChange(newValue);
                            }} />
                        <span className="typename">{option.name}</span><span className="des">{option.description ? option.description : null}</span>
                    </label>
                    {/* {touched &&
        (error && (
          <div className="error-text">
            {error}
          </div>
        ))} */}
                </li>)
        });
    }

    render() {
        let { meta: { touched, error, warning } } = this.props;
        return (
            <ul className="permission-list">
                {this.checkboxGroup()}
                {touched &&
                    (error && (
                        <div className="error-text">
                            {error}
                        </div>
                    ))}
            </ul>
        )
    }
}


export default CheckboxGroup;