import React, { Component } from 'react';
import { reduxForm, Field, change, FieldArray } from 'redux-form';

export class FieldFileArrayInput extends React.Component {
    constructor(props) {
        super(props)
        // this.onChange = this.onChange.bind(this)
        this.state = {
            count: 0,
            fields: [],
            allvalue: [],
        };

    }
    // onChange(e) {
    //   console.log('file value',e.target.files[0]);
    //   const { input: { onChange } } = this.props
    //   var valarr = this.state.allvalue.push(e.target.files[0]);
    //   onChange(valarr)
    // }
    addFiles = () => {
        if (this.state.count != 3) {
            var count = this.state.count + 1;
            this.setState({ count: count });

            // this.state.fields.push(count)
            // this.setState({ fields: this.state.fields.concat([{c:count}]) });
            this.setState(prevState => ({ fields: [...prevState.fields, ''] }))
        }
    }
    removeFiles = (index) => {
        // console.log('index', index);
        // var count = this.state.count - 1;
        // this.setState({ count: count });
        // this.state.fields.slice(index, 1);
        // // this.state.allvalue.slice(index, 1);
        // document.getElementById("cusid"+index).remove();
        let fields = [...this.state.fields];
        fields.splice(index, 1);
        this.setState({ fields });

    }

    // removeFiles = (idx) => () => {
    //     var count = this.state.count - 1;
    //     this.setState({ count: count });
    //     this.setState({ fields: this.state.fields.filter((s, sidx) => idx !== sidx) });
    // }

    handleChange = (i, event) => {
        let fields = [...this.state.fields];
        fields[i] = event;
        this.setState({ fields });
        console.log('handle files', event);
    }
    render() {
        // const { input: { value } } = this.props
        const { input: { value, onChange, name }, label, required, meta, } = this.props
        const { fields, allvalue } = this.state
        // const {input,label, required, meta, } = this.props  //whatever props you send to the component from redux-form Field
        return (
            <div><label>{label}</label>
                <div>
                    {
                        this.state.count ?
                            fields.map((field, i) => {
                                return (

                                    <div key={i} id={'cusid' + i}>
                                        <input
                                            type='file'
                                            id={'cfile' + i}
                                            // accept='.jpg, .png, .jpeg'
                                            // onChange={this.onChange}
                                            // onChange={(e) => this.props.input.onChange(e.target.files[0])}
                                            onChange={(e) => this.handleChange(i, e.target.files[0])}
                                        />
                                        <button type="button" onClick={() => this.removeFiles(i)}>
                                            Remove
              </button>
                                    </div>

                                )
                            })
                            : null
                    }
                    <button type="button" onClick={() => this.addFiles()}>
                        Add
      </button>
                </div>
            </div>
        )
    }
}