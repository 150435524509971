import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {

  }

  render() {
    return (
      <footer className="c-footer">
        <div>Copyright © {(new Date().getFullYear())}. All rights reserved.</div>
        <div className="ml-auto">Powered by&nbsp;Land Inspections</div>
      </footer>
    );
  }
}

const mapStateToProps = state => {

  return {

  };
};
export default connect(mapStateToProps)(withRouter(Footer));
