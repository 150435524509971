import React, { Component } from 'react';
import { connect } from 'react-redux';

 import './loader.css';

class MaterialLoader extends Component {
   // eslint-disable-next-line
  constructor(props, context) {
    super(props, context);
  }

  render() {
    console.log('call loader calling==',this.props.showing);
    if (!this.props.showing) {
      return null;
    }
    return (
      // <div className='loader-container'>
      //   <div className='loader-base'>
      //     <div className="preloader-wrapper big active">
      //         <div className="spinner-layer spinner-red">
      //           <div className="circle-clipper left">
      //             <div className="circle"></div>
      //           </div>
      //           <div className="gap-patch">
      //             <div className="circle"></div>
      //           </div>
      //           <div className="circle-clipper right">
      //             <div className="circle"></div>
      //           </div>
      //         </div>
      //       </div>
      //   </div>
      // </div>
      // <div className='loader-container'>
      //    <div className='loader-base'>
      // <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    
      // </div>
      // </div>
      <div className='loader-container'>
         <div className='loader-base'>
         <div class="lds-ripple"><div></div><div></div></div>
       </div>
       </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    showing: state.loader.materialLoaderShowing
  };
}

export default connect(mapStateToProps)(MaterialLoader);
