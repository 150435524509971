import React, { Component } from 'react';
import { reduxForm, Field, change, FieldArray } from 'redux-form';

export class FieldFileArrayInput1 extends React.Component {
    constructor(props) {
        super(props)
        // this.onChange = this.onChange.bind(this)
        this.state = {
            count: 1,
            fields: [1],
            allvalue:[],
        };

    }
    onChange(e) {
        console.log('raw',e)
      var valarr = this.state.allvalue.push(e);
     console.log('files array==>',valarr);
    }
    addFiles = () => {
        if (this.state.count != 3) {
            var count = this.state.count + 1;
            this.setState({ count: count });

            this.state.fields.push(count)
        }

    }
    removeFiles = (index) => {
        if (this.state.count > 1)
            console.log('index', index);
        var count = this.state.count - 1;
        this.setState({ count: count });
        this.state.fields.slice(index, 1);
        document.getElementById("cusid" + index).remove();
    }
    resetFiles = () => {
        document.getElementById("cfile0").value = "";
    }

    render() {
        // const { input: { value } } = this.props
        const { input: { value, onChange, name }, label, required, meta, } = this.props
        const { fields } = this.state
        // const {input,label, required, meta, } = this.props  //whatever props you send to the component from redux-form Field
        return (
            <div><label>{label}</label>
                <div>
                    {
                        this.state.count ?
                            fields.map((field, i) => {
                                return (
                                    <div key={i} id={'cusid' + i}>
                                        <input
                                            type='file'
                                            id={'cfile' + i}
                                            // accept='.jpg, .png, .jpeg'
                                            onChange={(e)=>this.onChange(e.target.files[0])}
                                            // onChange={(e) => this.props.input.onChange(e.target.files[0])}
                                        />
                                        {i + 1 > 1 ?
                                            <button type="button" onClick={() => this.removeFiles(i)}>
                                                Remove
              </button> :

                                            <button type="button" onClick={() => this.resetFiles(i)}>
                                                reset
              </button>
                                        }
                                    </div>
                                )
                            })
                            : null
                    }
                    <button type="button" onClick={() => this.addFiles()}>
                        Add
      </button>
                </div>
            </div>
        )
    }
}