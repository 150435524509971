import { ACTIONS } from '../../common/config/config';

const initialState = {
  countryList: null,
  stateList:null,
  cityList:null,
  dashboardCount:null,
  allReport:null,
};

const utilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UTILITY.GET_COUNTRY:
      return {
        ...state,
        countryList: action.payload,
      };
      case ACTIONS.UTILITY.GET_STATE:
      return {
        ...state,
        stateList: action.payload,
      };
      case ACTIONS.UTILITY.GET_CITY:
      return {
        ...state,
        cityList: action.payload,
      };
      case ACTIONS.UTILITY.DASHBOARD_COUNT:
      return {
        ...state,
        dashboardCount: action.payload,
      };
      case ACTIONS.UTILITY.GET_ALL_REPORT:
      return {
        ...state,
        allReport: action.payload,
      };

    default:
      return state;
  }
};

export default utilityReducer;
