import { ACTIONS } from '../../common/config/config';

const initialState = {
  friendList: null,
  messageDetails: null,
  selectedFriend: null,
  totalUnreadMsgs:null,
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.MESSAGES.GET_FRIEND_LIST:
      return {
        ...state,
        friendList: action.payload,
      };
    case ACTIONS.MESSAGES.GET_MSG_WITH_PARTICULAR_FRND:

      console.log('incomming payload from api =', action.payload.data[0])

      if (action.payload && action.payload.data.length && action.payload.data[0] && action.payload.data[0].current_page == 1) {
        state.messageDetails = null;
      }

      let prevData = state.messageDetails && state.messageDetails.dataset ? state.messageDetails.dataset : [];
      if (prevData.length) {
        let incommingData = action.payload && action.payload.data && action.payload.data.length && action.payload.data[0] && action.payload.data[0].data && action.payload.data[0].data.length ? action.payload.data[0].data : [];
        for (var i = 0; i < incommingData.length; i++) {
          prevData.unshift(incommingData[i])
        }
      } else {
        console.log('when message array empty')
        let incommingData = action.payload && action.payload.data && action.payload.data.length && action.payload.data[0] && action.payload.data[0].data && action.payload.data[0].data.length ? action.payload.data[0].data.reverse() : [];
        for (var i = 0; i < incommingData.length; i++) {
          prevData.push(incommingData[i])
        }
      }

      console.log('action.payload', action.payload);

      return {
        ...state,
        messageDetails: {
          ...(action.payload && action.payload.data && action.payload.data.length ? action.payload.data[0] : {}),
          dataset: prevData.map(x => Object.assign(x)),
          data: undefined
        }
      };

    case ACTIONS.MESSAGES.EMPTY_MSG_DETAILS_WITH_PARTICULAR_FRND:
      return {
        ...state,
        messageDetails: action.payload,
      };


    case ACTIONS.MESSAGES.SELECTED_CHAT_FRIEND:
      return {
        ...state,
        selectedFriend: action.payload,
      };
      case ACTIONS.MESSAGES.GET_ALL_CHAT_COUNT:
        return {
          ...state,
          totalUnreadMsgs: action.payload,
        };
    default:
      return state;
  }
};

export default messageReducer;
