import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, change } from 'redux-form';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { required, emailValidator, phoneValidator } from '../../common/validators/form-validators';
import { InputField, ReactSelect } from '../../common/form-elements/elements';
import { SAGA_ACTIONS, ACTIONS, ErrorMsg } from "../../common/config/config";

class CreateTitleOfficers extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
        this.showToaster = this.showToaster.bind(this);
    }
    showToaster(type, title, message) {
        this.toastr.add({
            type,
            title,
            position: 'top-left',
            attention: true,
            onAttentionClick: id => { },
            message,
            options: { showCloseButton: true },
            showCloseButton: true,
        });
    }
    titleOfficerFormSubmit = (data) => {
        // console.log('Submited data', data);
        var params = {
            name: data.name,
            email: data.email,
        }
        if (this.props && this.props.titleOfficer && this.props.titleOfficer.isUpdate) {
            var params = {
                status: data.status ? data.status.value : null,
                name: data.name,
                email: data.email,
                id: this.props.titleOfficer && this.props.titleOfficer.id ? this.props.titleOfficer.id : null
            }
            return new Promise((resolve, reject) => {
                this.props.dispatch({
                    type: SAGA_ACTIONS.TITLEOFFICERS.TITLE_OFFICERS_UPDATE,
                    payload: {
                        data: params,
                        // id: this.props && this.props.userData ? this.props.userData.user.id : null
                    },
                    callbackSuccess: resp => {
                        this.showToaster('success', 'Success', resp.data.message);
                        this.props.getAllTitleOfficers();
                        this.props.CloseTitleOfficer();
                    },
                    callbackError: e => {
                        // console.log('error',e.data) 
                        var errMsg = ErrorMsg(e.data);
                        this.showToaster('error', 'Error', errMsg);
                        reject(e);
                        // this.showToaster('error', 'Error', e.data.message);
                        // reject(e);
                    }
                });
                return resolve(true);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.props.dispatch({
                    type: SAGA_ACTIONS.TITLEOFFICERS.CREATE_TITLE_OFFICERS,
                    payload: params,
                    callbackSuccess: resp => {
                        this.showToaster('success', 'Success', resp.data.message);
                        this.props.getAllTitleOfficers();
                        this.props.CloseTitleOfficer();
                    },
                    callbackError: e => {
                        var errMsg = ErrorMsg(e.data);
                        this.showToaster('error', 'Error', errMsg);
                        reject(e);
                    }
                });
                return resolve(true);
            });
        }
    }

    render() {
        const options = [
            { value: 1, label: 'Active' },
            { value: 0, label: 'Inactive' },
        ];
        const { handleSubmit, submitting } = this.props;
        return (
            <Modal
                isOpen={this.props.isShown}
                toggle={this.props.CloseTitleOfficer}
                onClosed={this.props.CloseTitleOfficer}
                backdrop={'static'}
                size={'md'}
                fade={false}
                keyboard={false}
                className={'border-left-modal'}
            >
                <ModalHeader toggle={this.props.CloseTitleOfficer}>{this.props && this.props.modalTitle ? this.props.modalTitle : null}</ModalHeader>


                <form name="createTitleOfficersForm" onSubmit={handleSubmit(this.titleOfficerFormSubmit)}>
                    <ModalBody>
                        <div className="form-group">
                            <div className="row">
                                <div className="col pr-1">
                                    <label for="titleofficername">Title Officer Name</label>
                                    <Field component={InputField} name="name" placeholder="Title Officer Name" validate={[required]} />
                                </div>
                                <div className="col pl-1">
                                    <label for="titleofficeremail">Title Officer Email</label>
                                    <Field component={InputField} name="email" placeholder="Title Officer Email" validate={[required, emailValidator]} />
                                </div>
                            </div>
                        </div>
                        {this.props.titleOfficer && this.props.titleOfficer.isUpdate ?
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6 pr-1">
                                        <label for="status">Status</label>
                                        <Field component={ReactSelect} name="status" options={options} />
                                    </div>
                                </div>
                            </div> : null}

                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-success">{submitting ? 'Please wait...' : 'Save'}</button>
                        <button type="button" className="btn btn-warning" onClick={this.props.CloseTitleOfficer} >Cancel</button>

                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}
const CreateTitleOfficersForm = reduxForm({
    form: 'createTitleOfficersForm',
    destroyOnUnmount: true,
    enableReinitialize: true,
})(CreateTitleOfficers);
const mapStateToProps = (state, props) => {
    const initialValues = props.titleOfficer;
    return {
        initialValues,
    };
};
export default connect(mapStateToProps)(CreateTitleOfficersForm);