import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError } from 'redux-form';
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { emailValidator, required } from '../../common/validators/form-validators';
import { InputField, FieldFileInput } from '../../common/form-elements/elements';
import { SAGA_ACTIONS, ACTIONS } from "../../common/config/config";

// const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);
// const FileInput = ({ 
//   input: { value: omitValue, onChange, onBlur, ...inputProps }, 
//   meta: omitMeta, 
//   ...props 
// }) => {
//   return (
//     <input
//       onChange={adaptFileEventToValue(onChange)}
//       onBlur={adaptFileEventToValue(onBlur)}
//       type="file"
//       {...props.input}
//       {...props}
//     />
//   );
// };
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
      profileImage: '',
    };
    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
    this.showToaster = this.showToaster.bind(this);
  }

  showHideToggle = () => {
    var showHide = this.state.showHide ? false : true;
    this.setState({ showHide: showHide })
  }

  showToaster(type, title, message) {
    this.toastr.add({
      type,
      title,
      position: 'top-left',
      attention: true,
      onAttentionClick: id => { },
      message,
      options: { showCloseButton: true },
      showCloseButton: true,
    });
  }

  fileChange = (val) => {
    console.log('uploadfile1', val)
    let formData = new FormData();
    formData.append('file', val);
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: SAGA_ACTIONS.WORKORDER.SINGLE_INDIVIDUAL_FILE_UPLOAD,
        payload: {
          formData
        },
        callbackSuccess: (resp) => {
          console.log('incomming response file1=', resp)
          this.setState({ profileImage: resp.data.data.name }, () => console.log('uploaded image', this.state.profileImage))
        },
        callbackError: (err) => {
          console.log('error upload file1=', err)
        },
      });
      return resolve(true);
    });
  }

  profileFormSubmit = (data) => {
    // console.log('data', data);
    if (this.props.userDetails && this.props.userDetails.user_type == 2) {
      var params = {
        name: data && data.name ? data.name : null,
        phone: data && data.mobile ? data.mobile : null,
        title_company: data && data.titlecompany ? data.titlecompany : null,
        address: data && data.address ? data.address : null,
        profile_picture: this.state.profileImage ? this.state.profileImage : null
      }
    }
    else {
      var params = {
        name: data && data.name ? data.name : null,
        profile_picture: this.state.profileImage ? this.state.profileImage : null
      }
    }

    this.props.dispatch({
      type: SAGA_ACTIONS.USER.USER_INFO_UPDATE,
      payload: params,
      callbackSuccess: (resp) => {
        this.showToaster('success', 'Success', resp.data.message);
        this.setState({ profileImage: '' })
        this.props.dispatch({
          type: SAGA_ACTIONS.USER.ME,
          callbackSuccess: (resp1) => {
          },
          callbackError: er => {
            this.showToaster('error', 'Error', er.data.message);
          },
        });
      },
      callbackError: e => {
        this.showToaster('error', 'Error', e.data.message);

      },
    });

    if (data && data.password && data.confirmpassword) {
      var params1 = {
        password: data && data.password ? data.password : null,
      }
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PASSWORD_UPDATE,
        payload: params1,
        callbackSuccess: (resp) => {
          console.log('resp', resp);
          this.showToaster('success', 'Success', resp.data.message);
        },
        callbackError: e => {
          this.showToaster('error', 'Error', e.data.message);

        },
      });

    }
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div>
        <Sidebar />
        <div className="c-wrapper c-fixed-components">
          <Header pageTitle={"Profile"} />
          <div className="c-body">
            <main className="c-main">
              <div className="container-fluid">
                <div className="fade-in">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header"><strong>Profile</strong></div>
                        <div className="card-body">
                          <form name="userProfileForm" className="forms-sample" onSubmit={handleSubmit(this.profileFormSubmit)}>

                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label htmlFor="name">Name</label>
                                  {/* <input className="form-control" id="name" type="text" placeholder="Enter your name" /> */}
                                  <Field className="form-control" component={InputField} type="text" name="name" placeholder="Enter your name" validate={[required]} />
                                </div>
                              </div>
                            </div>
                            {this.props.userDetails && this.props.userDetails.user_type == 2 ?
                              <>
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="form-group">
                                      <label htmlFor="email">Email</label>
                                      <Field component={InputField} className="form-control" name="email" placeholder="Email" validate={[required, emailValidator]} readOnly={true} />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="form-group">
                                      <label htmlFor="mobile">Mobile</label>
                                      <Field component={InputField} name="mobile" placeholder="Mobile" validate={[required]} />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="form-group">
                                      <label htmlFor="titlecompany">Title Company</label>
                                      <Field component={InputField} className="form-control" name="titlecompany" placeholder="Title Company" validate={[required]} />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="form-group">
                                      <label htmlFor="address">Address</label>
                                      <Field component={InputField} className="form-control" name="address" placeholder="Address" validate={[required]} />
                                    </div>
                                  </div>
                                </div>
                              </>
                              : null}
                            {this.props.userDetails && this.props.userDetails.user_type == 3 ?
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor="profilepic">Profile Picture</label>
                                    <Field name="picture" component={FieldFileInput} onChange={this.fileChange} />
                                  </div>
                                </div>
                                {this.props.userDetails && this.props.userDetails.profile_picture ?
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <img width="100" height="100" src={this.props.userDetails && this.props.userDetails.profile_picture ? this.props.userDetails.profile_picture : null} alt={"user image"} />
                                    </div>
                                  </div> : null}
                              </div> : null}

                            <div className="form-group">
                              <span className="btn btn-secondary" onClick={this.showHideToggle}>Update Password</span>
                            </div>
                            {this.state.showHide ?
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Password</label>
                                    <Field component={InputField} type="password" name="password" validate={[required]} />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputConfirmPassword1">Confirm Password</label>
                                    <Field component={InputField} type="password" name="confirmpassword" validate={[required]} />
                                  </div>
                                </div>
                              </div>
                              : null}
                            <hr />
                            <div className="text-right mt-3">
                              <button type="submit" className="btn btn-primary mr-2">Update Profile</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* /.col*/}

                  </div>

                </div>
              </div>
            </main>



            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

const CreateProfileForm = reduxForm({
  form: 'userProfileForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate(values) {
    const errors = {};
    if (values.password != values.confirmpassword) {
      errors.confirmpassword = 'password not matched';
    }
    return errors;
  },
})(UserProfile);

function initialValuesSet(values) {
  var initialValue = {
    name: values && values.name ? values.name : null,
    email: values && values.email ? values.email : null,
    mobile: values && values.phone ? values.phone : null,
    titlecompany: values && values.title_company ? values.title_company : null,
    address: values && values.address ? values.address : null,
  };
  return initialValue;
}
const mapStateToProps = state => {

  const initialValues = state.user && state.user.user && state.user.user.data && state.user.user.data.length ? initialValuesSet(state.user.user.data[0]) : null;
  const userDetails = state.user && state.user.user && state.user.user.data && state.user.user.data.length ? state.user.user.data[0] : null;

  return {
    initialValues,
    userDetails
  };
};

export default connect(mapStateToProps)(CreateProfileForm);
// const mapStateToProps = state => {


//   return {

//   };
// };

// export default connect(mapStateToProps)(UserProfile);