import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { ACTIONS, SAGA_ACTIONS } from '../../common/config/config';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
    this.showToaster = this.showToaster.bind(this);
  }
  showToaster(type, title, message) {
    this.toastr.add({
      type,
      title,
      position: 'top-left',
      attention: true,
      onAttentionClick: id => { },
      message,
      options: { showCloseButton: true },
      showCloseButton: true,
    });
  }
  userLogOut = () => {
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.LOGOUT,
        callbackSuccess: (resp) => {
          this.props.history.push('/login');
          // this.showToaster('success', 'Success', 'Logout Successfully');
        },
        callbackError: e => {
          const message = 'Token error';
          console.log('error', e);
        },
      });
      return resolve(true);
    });
  }

  render() {
    const { pageTitle, userDetails } = this.props;
    // console.log('userDetails',userDetails);
    return (
      <header className="c-header c-header-light c-header-fixed c-header-with-subheader">
        <button className="c-header-toggler c-class-toggler d-lg-none mfe-auto" type="button" data-target="#sidebar" data-class="c-sidebar-show">
          <svg className="c-icon c-icon-lg">
            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-menu" />
          </svg>
        </button>
        <button className="c-header-toggler c-class-toggler mfs-3 d-md-down-none" type="button" data-target="#sidebar" data-class="c-sidebar-lg-show" responsive="true">
          <svg className="c-icon c-icon-lg">
            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-menu" />
          </svg>
        </button>
        <ul className="c-header-nav d-md-down-none">
          <li className="c-header-nav-item px-3">{pageTitle}</li>
        </ul>
        <ul className="c-header-nav ml-auto mr-4">
          {/* <li className="c-header-nav-item d-md-down-none mx-2"><a className="c-header-nav-link" href="#">
          <svg className="c-icon">
            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-bell" />
          </svg></a></li>
        <li className="c-header-nav-item d-md-down-none mx-2"><a className="c-header-nav-link" href="#">
          <svg className="c-icon">
            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-list-rich" />
          </svg></a></li> */}
          {/* <li className="c-header-nav-item d-md-down-none mx-2"><a className="c-header-nav-link" href="#">
          <svg className="c-icon">
            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-envelope-open" />
          </svg></a></li> */}
          <li className="c-header-nav-item dropdown"><a className="c-header-nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            <div className="c-avatar"><img className="c-avatar-img" src={userDetails && userDetails.profile_picture ? userDetails.profile_picture : 'assets/icons/no-photo.jpg'} alt={userDetails ? userDetails.email : null} /></div>
          </a>

            <div className="dropdown-menu dropdown-menu-right pt-0 cpointer">
              <span className="dropdown-item" onClick={this.userLogOut}>
                <svg className="c-icon mr-2">
                  <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-account-logout" />
                </svg> Logout</span>
            </div>
          </li>
        </ul>
        {/* <div className="c-subheader px-3">
        <ol className="breadcrumb border-0 m-0">
          <li className="breadcrumb-item">Home</li>
          <li className="breadcrumb-item"><a href="#">Admin</a></li>
          <li className="breadcrumb-item active">Dashboard</li>
        </ol>
      </div> */}
      </header>
    );
  }
}

const mapStateToProps = state => {
  const userDetails = state.user && state.user.user && state.user.user.data && state.user.user.data.length ? state.user.user.data[0] : null;
  return {
    userDetails
  };
};
export default connect(mapStateToProps)(withRouter(Header));
