import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { ACTIONS, APIS, STORAGE } from '../common/config/config';
function* setLoader(status) {
  yield put({
    type: status ? ACTIONS.LOADER.SHOW_LOADER : ACTIONS.LOADER.HIDE_LOADER,
  });
}

export function* getFrndList(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.GET, APIS.MESSAGES.GET_FRIEND_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield put({
        type: ACTIONS.MESSAGES.GET_FRIEND_LIST,
        payload: resp.data,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* getMessageDetails(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.GET, APIS.MESSAGES.GET_MSG_WITH_PARTICULAR_FRND, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield put({
        type: ACTIONS.MESSAGES.GET_MSG_WITH_PARTICULAR_FRND,
        payload: resp.data,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* saveOrSendMsg(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.MESSAGES.SAVE_OR_SEND_MSG, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* getAllMessagesCount(action) {
  try {
    yield setLoader(true);
    const data = action.payload;
    const resp = yield call(CallApi.GET, APIS.MESSAGES.GET_ALL_CHAT_COUNT, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield put({
        type: ACTIONS.MESSAGES.GET_ALL_CHAT_COUNT,
        payload: resp.data,
      });
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}

export function* fileSendOverMsg(action) {
  try {
    yield setLoader(true);
    const data = action.payload.formData;
    const resp = yield call(CallApi.FILEUPLOAD, APIS.MESSAGES.FILE_SEND_OVER_MESSAGE, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackSuccess && action.callbackSuccess(resp);
    } else {
      yield setLoader(false);
      // eslint-disable-next-line no-unused-expressions
      action && action.callbackError && action.callbackError(resp);
    }
  } catch (e) {
    yield setLoader(false);
    // eslint-disable-next-line no-unused-expressions
    action && action.callbackError && action.callbackError(e);
  }
}