import moment from 'moment';

export const emailValidator = value =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;
export const textOnly = value =>
  value && !/^[A-Z ]*$/i.test(value)
    ? 'Title should contain Alphabets and blank spaces only'
    : undefined;

export const permittedLength = value =>
  value && !(value.length > 1 && value.length <= 255)
    ? 'Title should contain 255 characters max'
    : undefined;

export const required = value => (value && value.trim() ? undefined : 'This field is required');
export const required_dropdown = value => (value ? undefined : 'This field is required');
export const required_select = value => (value && value.length ? undefined : 'This field is required');
export const requiredDate = value => (value && moment(value).isValid() ? undefined : 'Valid Date required');
export const required_checkbox = value => (value && value.length ? undefined : 'At least one value is required');



export const passwordMinLength8 = value =>
  value && value.length < 8 ? 'Password should be minimum 8 chars' : undefined;

export const phoneValidator = value =>
  !/^[0-9]{10}$/g.test(value) ? 'Invalid phone number' : undefined;

  export const numberValidator = value =>
  value ? (!/^([0-9])+?$/g.test(value) ? 'Invalid number' : undefined) : undefined;

  export const cardValidator = value =>
  !/^\d{16}$/.test(value) ? 'Invalid card number' : undefined;

  export const usZipCodeValidator = value =>
  
  !/^[0-9]{5}(?:-[0-9]{4})?$/g.test(value) ? 'Invalid Zip code' : undefined;
