import { ACTIONS } from '../../common/config/config';

const initialState = {
  titleOfficersList: null,
  titleOfficersListWithAllDetails: null,
  titleOfficerLeave: null,
  availableTitleOfficers: null,
};

const officersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TITLEOFFICERS.TITLE_OFFICERS_LIST:
      return {
        ...state,
        titleOfficersList: action.payload,
      };
    case ACTIONS.TITLEOFFICERS.TITLE_OFFICERS_LIST_WITH_ALL_DETAILS:
      return {
        ...state,
        titleOfficersListWithAllDetails: action.payload,
      };
    case ACTIONS.TITLEOFFICERS.GET_TITLE_OFFICERS_LEAVE:
      return {
        ...state,
        titleOfficerLeave: action.payload,
      };
    case ACTIONS.TITLEOFFICERS.GET_AVAILABLE_COWORKER:
      return {
        ...state,
        availableTitleOfficers: action.payload,
      };
    default:
      return state;
  }
};

export default officersReducer;
