import { ACTIONS } from '../../common/config/config';

const initialState = {
  materialLoaderShowing: false,
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOADER.SHOW_LOADER:
      console.log('call true');
      return {
        ...state,
        materialLoaderShowing: true,
      };
    case ACTIONS.LOADER.HIDE_LOADER:
        console.log('call false');
      return {
        ...state,
        materialLoaderShowing: false,
      };
    default:
      return state;
  }
};

export default loaderReducer;
