import React from 'react';
import { Input, FormFeedback } from 'reactstrap';
import Switch from "react-switch";
import Select from 'react-select';

export const formErrorStyle = {
  fontSize: '12px',
  color: '#f00',
  display: 'inline-block',
};

export const InputField = ({
  input,
  className,
  name,
  id,
  readOnly,
  label,
  placeholder,
  value,
  type,
  style,
  autoComplete,
  disabled,
  checked,
  meta: { touched, error, warning },
}) => (
    <>
      <Input
        {...input}
        type={type}
        id={id}
        style={style}
        placeholder={placeholder}
        className={className}
        readOnly={readOnly}
        autoComplete={autoComplete}
        disabled={disabled}
        checked={checked}
        invalid={error && touched ? true : undefined}
      />

      {touched &&
        (error && (
          <FormFeedback>
            {error}
          </FormFeedback>
        ))}
    </>
  );
export const TextAreaField = ({
  input,
  className,
  name,
  id,
  readOnly,
  label,
  placeholder,
  value,
  type,
  row,
  col,
  style,
  autoComplete,
  disabled,
  checked,
  meta: { touched, error, warning },
}) => (
    <>
      <textarea
        {...input}
        type={type}
        id={id}
        row={row}
        col={col}
        style={style}
        placeholder={placeholder}
        className={className}
        readOnly={readOnly}
        autoComplete={autoComplete}
        disabled={disabled}
        checked={checked}
        invalid={error && touched ? 'invalid' : undefined}
      ></textarea>

      {touched &&
        (error && (
          <span className="error-text">
            {error}
          </span>
        ))}
    </>
  );
export class SelectField extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    const {
      input: { value, onChange, name },
      placeholder,
      disabled,
      readOnly,
      options,
      isMulti,
      selectText,
      className,
      meta: { touched, error }
    } = this.props
    return (
      <>
        <select
          disabled={disabled}
          readOnly={readOnly}
          value={value}
          style={{ minHeight: '45px' }}
          className={className}
          placeholder={placeholder}
          onChange={(sel) => this.props.input.onChange(sel)}
        >
          <option value="">{selectText}</option>
          {
            options.map((o, i) => {
              return <option key={i} value={o.id}>{o.value}</option>
            })
          }
        </select>
        {/* {touched &&
              (error && (
                <FormFeedback>
                  {error}
                </FormFeedback>
              ))} */}
        {touched &&
          (error && (
            <span className="error-text">
              {error}
            </span>
          ))}
      </>
    )
  }
}

export class SwitchField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: false };
  }
  render() {
    const { input: { value, onChange, name }, placeholder, className, interval, disabled, readOnly, meta: { touched, error } } = this.props
    return (
      <>
        <Switch
          onChange={(sel) => this.props.input.onChange(sel)}
          checked={this.props.input.value ? this.props.input.value : false}
        />
        {touched &&
          (error && (
            <FormFeedback>
              {error}
            </FormFeedback>
          ))}
      </>
    )
  }
}
export class ReactSelect extends React.Component {
  constructor(props) {
    super(props);
    this.inputChange = this.inputChange.bind(this);
  }

  inputChange(evt) {
    this.props.input.onChange(evt);
  }

  render() {
    const {
      input: { value, onChange, onKeyDown },
      label,
      placeholder,
      options,
      nameField,
      idField,
      className,
      meta: { touched, error, warning },
      defaultVal
    } = this.props;
    return (
      <>
        <Select
          value={value}
          onChange={this.inputChange}
          //  menuIsOpen
          classNamePrefix="my-menu"
          onKeyDown={this.props.onKeyDown}
          options={options}
          className={className}
          isSearchable
          isClearable={this.props.clearable}
          placeholder={placeholder}
        />
        {touched &&
          (error && (
            <span className="error-text">{error}</span>
          ))}
      </>
    );
  }
}


export class ReactRegSelect extends React.Component {
  constructor(props) {
    super(props);
    this.inputChange = this.inputChange.bind(this);
  }

  inputChange(evt) {
    this.props.input.onChange(evt);
  }

  render() {
    const {
      input: { value, onChange, onKeyDown },
      label,
      placeholder,
      options,
      nameField,
      idField,
      className,
      meta: { touched, error, warning },
      defaultVal
    } = this.props;
    return (
      // <div className="form-group mb-3">
      <div className={touched && error ? 'form-group mb-3 height60' : 'form-group mb-3'}>

        <div className="input-group-prepend state"><span className="input-group-text">
          <svg className="c-icon">
            <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-bank" />
          </svg></span>
          <Select
            value={value}
            onChange={this.inputChange}
            //  menuIsOpen
            classNamePrefix="my-menu"
            onKeyDown={this.props.onKeyDown}
            options={options}
            className={className}
            isSearchable
            isClearable={this.props.clearable}
            placeholder={placeholder}
          />
          {touched &&
            (error && (
              <span className="error-text">{error}</span>
            ))}
        </div>
      </div>
    );
  }
}





export class FieldFileInput extends React.Component {
  constructor(props) {
    super(props)
    // this.onChange = this.onChange.bind(this)
  }
  // onChange(e) {
  //   console.log('file value',e.target.files[0]);
  //   const { input: { onChange } } = this.props
  //   onChange(e.target.files[0])
  // }
  render() {
    // const { input: { value } } = this.props
    const { input: { value, onChange, name }, label, required, meta, } = this.props
    // const {input,label, required, meta, } = this.props  //whatever props you send to the component from redux-form Field
    return (
      <div><label>{label}</label>
        <div>
          <input
            type='file'
            // accept='.jpg, .png, .jpeg'
            // onChange={this.onChange}
            onChange={(e) => this.props.input.onChange(e.target.files[0])}
          />
        </div>
      </div>
    )
  }
}
