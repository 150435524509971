import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
export default class ReactDatePicker extends React.Component {
  state = {
    selectedDate: null,
  };

  componentWillMount() {
    if (this.props.input.value) {
      let dt = moment(`${moment().format('DD/MM/YYYY')} ${this.props.input.value}`, 'DD/MM/YYYY HH:mm:ss').toDate();
      this.setState({
        selectedDate: dt,
      });
    }
  }

  componentWillReceiveProps(next_props) {
    if (next_props.input && next_props.input.value && !this.props.input.value) {
      let dt = moment(`${moment().format('DD/MM/YYYY')} ${next_props.input.value}`, 'DD/MM/YYYY HH:mm:ss').toDate();
      this.setState({
        selectedDate: dt,
      });
    }
  }


  handleChange = (date) => {
    console.log('test', typeof date);
    this.setState({
      selectedDate: date,
    });
    console.log('this.props.input', this.props);
    this.props.input.onChange(new Date(date));
  }

  render() {
    const {
      input: { value, onChange },
      meta: { touched, error },
      ...rest
    } = this.props;

    return (
      <div>
        <DatePicker
          {...rest}
          selected={value}
          onChange={this.handleChange}
          showTimeSelectOnly={this.props.showtimeonly ? true : false}
          showTimeSelect={this.props.activeTime ? true : false}
          dateFormat={this.props.dateTimeFormat}
          timeIntervals={this.props.timeIntervals ? this.props.timeIntervals : 1}
          minDate={new Date()}
        />
        {touched &&
          (error && (
            <span className="error-text">
              {error}
            </span>
          ))}
      </div>
    );
  }
}