import React, { Component } from 'react';
import { connect } from "react-redux";
import Switch from "react-switch";
import ReactPaginate from 'react-paginate';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import Select from 'react-select'
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { confirmAlert } from 'react-confirm-alert';
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import { SAGA_ACTIONS, ACTIONS } from "../../common/config/config";
import CreateTitleOfficers from './createTitleOfficers';
class TitleOfficers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenStatus: '',
            name: '',
            email: '',
            pageNumber: 0,
            isShown: false,
            modalTitle: '',
            titleOfficer: '',
        };
        this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
        this.showToaster = this.showToaster.bind(this);
    }

    showToaster(type, title, message) {
        this.toastr.add({
            type,
            title,
            position: 'top-left',
            attention: true,
            onAttentionClick: id => { },
            message,
            options: { showCloseButton: true },
            showCloseButton: true,
        });
    }
    componentWillMount() {
        this.getAllTitleOfficers();

    }
    getAllTitleOfficers = (name = null, tokenstatus = null, page = 1, email = null) => {
        this.props.dispatch({
            type: SAGA_ACTIONS.TITLEOFFICERS.TITLE_OFFICERS_LIST_WITH_ALL_DETAILS,
            payload: {
                list: 1,
                page: page,
                search_name: name,
                status: tokenstatus ? tokenstatus.value : null,
                srch_email: email
            },
        });
    }

    handlePageClick = e => {
        this.setState({ pageNumber: e.selected });
        var status = this.state.tokenStatus ? this.state.tokenStatus : null;
        this.getAllTitleOfficers(this.state.name, status, e.selected + 1, this.state.email);
    };

    handleChange = (tokenStatus) => {
        // this.setState({
        //   tokenStatus: data
        // }, () => { console.log('tokenStatus =', this.state.tokenStatus) })
        this.setState({ tokenStatus: tokenStatus });
        console.log(`Option selected:`, tokenStatus);
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    handlEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    filterResult = () => {
        this.setState({ pageNumber: 0 });
        var status = this.state.tokenStatus ? this.state.tokenStatus : null;
        var page = 1;
        this.getAllTitleOfficers(this.state.name, status, page, this.state.email);
    }
    resetResult = () => {
        this.setState({ pageNumber: 0 });
        this.setState({ name: '', tokenStatus: null, email: '' })
        this.getAllTitleOfficers();
    }

    statusToggle = (data) => {
        console.log('data', data);
        return new Promise((resolve, reject) => {
            this.props.dispatch({
                type: SAGA_ACTIONS.SUBSCRIPTION.STATUS_CHANGE_TOKEN,
                payload: {
                    token_id: data.tokenId.id,
                    status: data.tokenId.tokenstatus == 1 ? 2 : 1
                },
                callbackSuccess: resp => {
                    this.showToaster('success', 'Success', resp.data.msg);
                    this.getAllSubscribedUsers(this.state.name, this.state.tokenStatus.value);

                },
                callbackError: resp => {
                    this.showToaster('error', 'Error', resp.data.msg);
                },
            });
            return resolve(true);
        });
    }

    createTitleOfficer = () => {
        var titleOfficer = {
            name: null,
            email: null,
            status: null,
            isUpdate: false,
        }
        this.setState({ isModalShow: true, modalTitle: 'Add Title Officer', titleOfficer: titleOfficer });
    }

    CloseTitleOfficer = () => {
        this.setState({ isModalShow: false, titleOfficer: null });
    }

    editTitleOfficers = (tofficer) => {
        console.log('tofficer',tofficer)
        var titleOfficer = {
            name: tofficer.name,
            email: tofficer.email,
            id: tofficer.id,
            status: tofficer.status?{value: 1, label: 'Active'}:{value: 0, label: 'Inactive'},
            isUpdate: true,
        }
        this.setState({ isModalShow: true, modalTitle: 'Edit Title Officer', titleOfficer: titleOfficer });
    }


    render() {
        const options = [
            { value: 1, label: 'Active' },
            { value: 0, label: 'Inactive' },
        ];
        return (
            <div>
                <Sidebar />
                <div className="c-wrapper c-fixed-components">
                    <Header pageTitle={"Title Officers"} />
                    <div className="c-body">
                        <main className="c-main">
                            <div className="container-fluid">
                                <div className="fade-in">
                                    <div className="row vertical-center">

                                        <div className="col-2">
                                            <div className="form-group form-focus">
                                                <label className="focus-label">Name</label>
                                                <input type="text" id="name" className="form-control floating" name="name" value={this.state.name} onChange={this.handleNameChange} />
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group form-focus">
                                                <label className="focus-label">Email</label>
                                                <input type="text" id="email" className="form-control floating" name="email" value={this.state.email} onChange={this.handlEmailChange} />
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group form-focus">
                                                <label className="focus-label">Status</label>
                                                <div className="cal-icon react-cus-dropdown">
                                                    <Select
                                                        value={this.state.tokenStatus}
                                                        onChange={this.handleChange}
                                                        classNamePrefix="my-menu"
                                                        options={options}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 mt-adjust">
                                            <button type="button" className="btn btn-success btn-block" onClick={this.filterResult}> Search </button>
                                        </div>
                                        <div className="col-2 mt-adjust">
                                            <button type="button" className="btn btn-warning btn-block text-white" onClick={this.resetResult}> Reset </button>
                                        </div>


                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <span className="left-side"><i className="fa fa-align-justify" /> Title Officers List</span>
                                                    {/* <span className="create-button right-side" onClick={this.createTitleOfficer}>Create Title Officer</span> */}
                                                    <button type="button" className="btn btn-outline-info right-side" onClick={this.createTitleOfficer}> Create Title Officer </button>
                                                </div>

                                                <div className="card-body">
                                                    <table className="table table-responsive-sm table-bordered table-striped table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.props && this.props.titleOfficersList && this.props.titleOfficersList.length ? (
                                                                this.props.titleOfficersList.map(tofficer => {
                                                                    return (
                                                                        <tr key={tofficer.id}>
                                                                            <td>
                                                                                {tofficer && tofficer.name ? tofficer.name : null}
                                                                            </td>
                                                                            <td>
                                                                                {tofficer && tofficer.email ? tofficer.email : null}
                                                                            </td>
                                                                            <td>
                                                                                {tofficer && tofficer.status ? <span className="badge badge-success">Active</span> : <span className="badge badge-secondary">Inactive</span>}
                                                                            </td>
                                                                            <td className="view files" width="5%">
                                                                                <span onClick={() => this.editTitleOfficers(tofficer)}>
                                                                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>

                                                                                </span>
                                                                                {/* Edit */}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                    <tr>
                                                                        <td colSpan={70} className="no-record">
                                                                            no record
                              </td>
                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </table>
                                                    <nav>
                                                        {/* <ul className="pagination">
                                                            <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                                                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">4</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                        </ul> */}
                                                        {this.props && this.props.totalNoOftitleOfficers ?
                                                            <div style={{ textAlign: 'center' }}>
                                                                <ReactPaginate
                                                                    previousLabel={'Prev'}
                                                                    nextLabel={'Next'}
                                                                    breakLabel="..."
                                                                    breakClassName="break-me"
                                                                    pageCount={Math.ceil(this.props.totalNoOftitleOfficers / 10)}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={this.handlePageClick}
                                                                    containerClassName="pagination"
                                                                    subContainerClassName="pages pagination"
                                                                    activeClassName="active"
                                                                    pageClassName="page-item"
                                                                    pageLinkClassName="page-link"  
                                                                    previousClassName="page-item" 
                                                                    nextClassName="page-item" 
                                                                    previousLinkClassName="page-link"
                                                                    nextLinkClassName="page-link"                                    
                                                                    forcePage={this.state.pageNumber}
                                                                />
                                                            </div> : null}
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /.col*/}
                                    </div>
                                    {/* /.row*/}
                                </div>
                            </div>
                        </main>
                        <Footer />
                    </div>
                </div>
                <CreateTitleOfficers
                    CloseTitleOfficer={this.CloseTitleOfficer}
                    isShown={this.state.isModalShow}
                    modalTitle={this.state.modalTitle}
                    getAllTitleOfficers={this.getAllTitleOfficers}
                    titleOfficer={this.state.titleOfficer}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const titleOfficersList = state.officer && state.officer.titleOfficersListWithAllDetails && state.officer.titleOfficersListWithAllDetails.data && state.officer.titleOfficersListWithAllDetails.data.length && state.officer.titleOfficersListWithAllDetails.data[0] && state.officer.titleOfficersListWithAllDetails.data[0].data ? state.officer.titleOfficersListWithAllDetails.data[0].data : [];
    const totalNoOftitleOfficers = state.officer && state.officer.titleOfficersListWithAllDetails && state.officer.titleOfficersListWithAllDetails.data && state.officer.titleOfficersListWithAllDetails.data.length && state.officer.titleOfficersListWithAllDetails.data[0] && state.officer.titleOfficersListWithAllDetails.data[0].data ? state.officer.titleOfficersListWithAllDetails.data[0].total : 0;
    return {
        titleOfficersList,
        totalNoOftitleOfficers
    };
};

export default connect(mapStateToProps)(TitleOfficers);