import { takeLatest, all } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../common/config/config';
import { loginUser, logoutUser, getMe, registrationUser, userActivate, forgotPassword, passwordUpdate, userDetailsUpdate } from './user.saga';
import { getWorkOrderList, saveWorkOrder, cancelWorkOrder, uploadSingleFile } from './workorder.saga';
import { titleOfficersCreate, titleOfficerActivate, titleOfficersList, titleOfficersListWithAllDetails, titleOfficersUpdate, getTitleOfficersLeave, createTitleOfficersLeave, deleteTitleOfficersLeave, getAvailableCoWorkers } from './officers.saga';
import { getCountryList, getStateList, getCityList, getDashboardCount, getAllReport, reportViewOrDownload } from './utility.saga';
import { getFrndList, getMessageDetails, saveOrSendMsg,getAllMessagesCount,fileSendOverMsg } from './message.saga';


export function* sagas() {
    yield all([
        takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
        takeLatest(SAGA_ACTIONS.USER.REGISTRATION, registrationUser),
        takeLatest(SAGA_ACTIONS.USER.LOGOUT, logoutUser),
        takeLatest(SAGA_ACTIONS.USER.ME, getMe),
        takeLatest(SAGA_ACTIONS.USER.MAIN_OFFICER_ACTIVATE, userActivate),
        takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),
        takeLatest(SAGA_ACTIONS.USER.PASSWORD_UPDATE, passwordUpdate),
        takeLatest(SAGA_ACTIONS.USER.USER_INFO_UPDATE, userDetailsUpdate),
        takeLatest(SAGA_ACTIONS.WORKORDER.ORDER_LIST, getWorkOrderList),
        takeLatest(SAGA_ACTIONS.WORKORDER.ORDER_SAVE, saveWorkOrder),
        takeLatest(SAGA_ACTIONS.WORKORDER.ORDER_CANCEL, cancelWorkOrder),
        takeLatest(SAGA_ACTIONS.WORKORDER.SINGLE_INDIVIDUAL_FILE_UPLOAD, uploadSingleFile),
        takeLatest(SAGA_ACTIONS.TITLEOFFICERS.CREATE_TITLE_OFFICERS, titleOfficersCreate),
        takeLatest(SAGA_ACTIONS.TITLEOFFICERS.TITLE_OFFICER_ACTIVATE, titleOfficerActivate),
        takeLatest(SAGA_ACTIONS.TITLEOFFICERS.TITLE_OFFICERS_LIST, titleOfficersList),
        takeLatest(SAGA_ACTIONS.TITLEOFFICERS.TITLE_OFFICERS_LIST_WITH_ALL_DETAILS, titleOfficersListWithAllDetails),
        takeLatest(SAGA_ACTIONS.TITLEOFFICERS.TITLE_OFFICERS_UPDATE, titleOfficersUpdate),
        takeLatest(SAGA_ACTIONS.TITLEOFFICERS.GET_TITLE_OFFICERS_LEAVE, getTitleOfficersLeave),
        takeLatest(SAGA_ACTIONS.TITLEOFFICERS.ADD_TITLE_OFFICERS_LEAVE, createTitleOfficersLeave),
        takeLatest(SAGA_ACTIONS.TITLEOFFICERS.REMOVE_TITLE_OFFICERS_LEAVE, deleteTitleOfficersLeave),
        takeLatest(SAGA_ACTIONS.TITLEOFFICERS.GET_AVAILABLE_COWORKER,  getAvailableCoWorkers),
        takeLatest(SAGA_ACTIONS.UTILITY.GET_COUNTRY, getCountryList),
        takeLatest(SAGA_ACTIONS.UTILITY.GET_STATE, getStateList),
        takeLatest(SAGA_ACTIONS.UTILITY.GET_CITY, getCityList),
        takeLatest(SAGA_ACTIONS.UTILITY.DASHBOARD_COUNT, getDashboardCount),
        takeLatest(SAGA_ACTIONS.UTILITY.GET_ALL_REPORT, getAllReport),
        takeLatest(SAGA_ACTIONS.UTILITY.REPORT_VIEW_OR_DOWNLOAD, reportViewOrDownload),
        takeLatest(SAGA_ACTIONS.MESSAGES.GET_FRIEND_LIST, getFrndList),
        takeLatest(SAGA_ACTIONS.MESSAGES.GET_MSG_WITH_PARTICULAR_FRND, getMessageDetails),
        takeLatest(SAGA_ACTIONS.MESSAGES.SAVE_OR_SEND_MSG, saveOrSendMsg),
        takeLatest(SAGA_ACTIONS.MESSAGES.GET_ALL_CHAT_COUNT,  getAllMessagesCount),
        takeLatest(SAGA_ACTIONS.MESSAGES.FILE_SEND_OVER_MESSAGE,  fileSendOverMsg),
        
       
    ]);
}