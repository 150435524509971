import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field, change, FieldArray } from 'redux-form';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { required, requiredDate, required_checkbox, required_dropdown } from '../../common/validators/form-validators';
import { InputField, ReactSelect, FieldFileInput } from '../../common/form-elements/elements';
import ReactDatePicker from '../../common/form-elements/reactDatePicker';
import { SAGA_ACTIONS, ACTIONS, ErrorMsg, customDateFormat } from "../../common/config/config";
import CheckboxGroup from './checkboxgroup';
import { confirmAlert } from 'react-confirm-alert';
import { FieldFileArrayInput } from './formfilearray';
import { FieldFileArrayInput1 } from './formfilearray1';
import { FieldFileArrayInput2 } from './formfilearray2';

const renderField = ({ fields, input, handleDelete, index, label }) => {
    // console.log('sdasd', input)
    return (
        <div>
            <input type="file" {...input} value={null} />
            <span>{input && input.value && input.value.file ? input.value.file.name : ''}</span>
            <label>
                {" "}
                - id: {label}, index = {index}
            </label>
        </div>)
};

const Guid = () => {
    const s4 = () =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    return `${s4()}`;
};


const renderFieldList = ({ fields }) => (

    <div>

        {fields.map((field, index) => {
            const { id } = fields.get(index);
            // Below is how to access the id that was added furing he push
            return (
                <div key={index}>
                    <Field
                        name={field}
                        component={renderField}
                        index={index}
                        label={id}
                    />
                    <button type="button" onClick={() => fields.remove(index)}>
                        Delete
                    </button>
                </div>
            );
        })}

        <button type="button" onClick={() => fields.push({ id: Guid() })}>
            {/* <button type="button" onClick={this.addfields()}> */}
            Add
        </button>
    </div>
);


class CreateWorkRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // fileCount1: 1,
            // fileCount2: 0,
            // fileCount3: 0,
            // totalCount: 1,
            upLoadedFiles: '',
            isStateSelected:false,
            selectedState:''
            // uploadedFiles: [{ file1: '', file2: '', file3: '' }]
        };
        this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
        this.showToaster = this.showToaster.bind(this);
    }
    showToaster(type, title, message) {
        this.toastr.add({
            type,
            title,
            position: 'top-left',
            attention: true,
            onAttentionClick: id => { },
            message,
            options: { showCloseButton: true },
            showCloseButton: true,
        });
    }
    titleOfficerFormSubmit = (data) => {
        //console.log('Submited data', data);

        var sendUploadedFiles = [];
        // if (this.state.uploadedFiles[0].file1 != '') {
        //     sendUploadedFiles.push(this.state.uploadedFiles[0].file1)
        // }
        // if (this.state.uploadedFiles[0].file2 != '') {
        //     sendUploadedFiles.push(this.state.uploadedFiles[0].file2)
        // }
        // if (this.state.uploadedFiles[0].file3 != '') {
        //     sendUploadedFiles.push(this.state.uploadedFiles[0].file3)
        // }

        // console.log('sendUploadedFiles',sendUploadedFiles)
        if (this.props.userDetails && this.props.userDetails.user_type == 2) {
            var titleOfficer = data && data.toofficer && data.toofficer.value ? data.toofficer.value : null;
        } else {
            var titleOfficer = this.props.userDetails && this.props.userDetails.id ? this.props.userDetails.id : null;
        }

        var params = {
            title_officer: titleOfficer,
            date_of_request: moment(data.dateofrequest).format('DD/MM/YYYY'),
            apn: data.apn,
            additional_apn: null,
            lot: data.lots,
            gate_code: data.gatecode,
            property_inspection: data.propertyinspection,
            service_type: data.servicetype,
            coe_date: data.coedate ? moment(data.coedate).format('DD/MM/YYYY') : null,
            additional_info_for_inspector: data.infoinspector,
            address_of_piq: data.addressofpiq,
            country: data && data.country ? data.country : null,
            state: this.props && this.props.workOrder && this.props.workOrder.state_id ? this.props.workOrder.state_id : null,
            city: this.props && this.props.workOrder && this.props.workOrder.city_id ? this.props.workOrder.city_id : null,
            zipcode: data.zip,
            name: data.name,
            company: data.company,
            address: data.address,
            phone: data.phone,
            order_number: data.ordernumber,
            recorded_owners: data.recordowner,
            reportState: this.state.selectedState,
            uploded_files: this.state.upLoadedFiles ? this.state.upLoadedFiles : []
        }
        console.log('data api',params);
        return new Promise((resolve, reject) => {
            this.props.dispatch({
                type: SAGA_ACTIONS.WORKORDER.ORDER_SAVE,
                payload: params,
                callbackSuccess: resp => {
                    this.showToaster('success', 'Success', resp.data.message);
                    this.props.getAllworkOrders();
                    this.props.CloseWorkOrder();
                    confirmAlert({
                        title: 'Success',
                        message: 'YOUR REQUEST HAS BEEN RECEIVED. WE WILL LET YOU KNOW ONCE PROCESSED.',
                        buttons: [
                            {
                                label: 'OKAY',
                            }

                        ]
                    });

                    this.setState({ upLoadedFiles: '' })
                },
                callbackError: e => {
                    // console.log('error',e.data)
                    var errMsg = ErrorMsg(e.data);
                    this.showToaster('error', 'Error', errMsg);
                    reject(e);
                }
            });
            return resolve(true);
        });

        //    var sendUploadedFiles = Object.values(this.state.uploadedFiles[0]);
        //    console.log('sendUploadedFiles',sendUploadedFiles)

        // var sendUploadedFiles = [];
        // if (this.state.uploadedFiles[0].file1 != '') {
        //     sendUploadedFiles.push(this.state.uploadedFiles[0].file1)
        // }
        // if (this.state.uploadedFiles[0].file2 != '') {
        //     sendUploadedFiles.push(this.state.uploadedFiles[0].file2)
        // }
        // if (this.state.uploadedFiles[0].file3 != '') {
        //     sendUploadedFiles.push(this.state.uploadedFiles[0].file3)
        // }

        // console.log('sendUploadedFiles',sendUploadedFiles)

        // if (data.uploadfile1 || data.uploadfile2 || data.uploadfile3) {
        //     console.log('data.uploadfile1', data.uploadfile1)
        //     console.log('data.uploadfile2', data.uploadfile2)
        //     console.log('data.uploadfile3', data.uploadfile3)
        //     // let formData = new FormData();
        //     // for (var i = 0; i < data.uploadfile.length; i++) {
        //     //     formData.append('file['+i+']', data.uploadfile[i]);
        //     // }
        //     // console.log('hello upload==',formData);
        // }


    }


    handleOpenClick = (e) => {
        this.refs.fileUploader.click();
    }
    onChangeState = (evt) => {
        let selectedState = evt.target.value;
        this.setState({selectedState:selectedState});
        if(selectedState !== ''){
            this.setState({isStateSelected:true});
        }else{
            this.setState({isStateSelected:false});
        }
    }
    onFileChange = (e) => {
        console.log('uploaedfile', e.target.files[0])
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        return new Promise((resolve, reject) => {
            this.props.dispatch({
                type: SAGA_ACTIONS.WORKORDER.SINGLE_INDIVIDUAL_FILE_UPLOAD,
                payload: {
                    formData
                },
                callbackSuccess: (resp) => {
                    console.log('incomming file upload response', resp)
                    this.setState(prevState => ({ upLoadedFiles: [...prevState.upLoadedFiles, resp.data.data.name] }), () => console.log('full state===>>', this.state.upLoadedFiles));

                },
                callbackError: (err) => {
                    console.log('error upload file1=', err)
                },
            });
            return resolve(true);
        });



        // res.data.userCreated.imgCollection.map(x => {
        //     // upLoadedFiles.push(x)
        //     this.setState(prevState => ({ upLoadedFiles: [...prevState.upLoadedFiles, x] }), () => console.log('full state===>>', this.state.upLoadedFiles));
        // })
    }
    getIcon = (url) => {
        var ext = url.substr(url.lastIndexOf('.') + 1);
        var file = '';
        switch (ext) {
            case 'jpg':
                file = 'assets/icons/png.png'
                break;
            case 'png':
                file = 'assets/icons/png.png'
                break;
            case 'doc':
                file = 'assets/icons/doc.png'
                break;
            case 'pdf':
                file = 'assets/icons/pdfx.png'
                break;
            case 'docx':
                file = 'assets/icons/doc.png'
                break;
            case 'xlsx':
                file = 'assets/icons/excel.png'
                break;

            default:
                file = 'assets/icons/file.png'
        }

        console.log('extension', file);
        return (file)
    }


    render() {
        const propertyInspection = [
            { id: 1, name: "ALTA", value: "alta", description: "Includes the following:address verification, possession, improvements,visual easement check encroachment check,ingress/egress, structures, unrecorded leases,evidence of work started" },
            { id: 2, name: "Foundation Inspection 102.4", value: "foundation-inspection-102.4", description: "" },
            { id: 3, name: "Foundation Endorsement 102.5 (+$10)", value: "foundation-endorsement-102.5-(+$10)", description: "Please provide survey and commitment" },
            { id: 4, name: "Completion Check", value: "completion-check", description: "" },
            { id: 5, name: "Priority Inspection (Mechanic's Lien)", value: "priority-inspection-(mechanic's-lien)", description: "" },
        ];
        const serviceType = [
            { id: 1, name: "STANDARD - ", value: "standard", description: "Three business days" },
            { id: 2, name: "RUSH - ", value: "rush-(+$10)", description: " Completed next business-day by 2pm" },
            { id: 3, name: "SAME DAY INSPECTION - ", value: "super-rush-($20)", description: " Must be submitted by 1pm to allow inspector sufficient travel time." },
            { id: 4, name: "DAY OF CLOSE INSPECTION - ", value: "inspection-on-day-of-close", description: " Completed by 2:00 PM on COE date specified below:" },
        ];
        const multipleAPNs = [
            { id: 1, name: "Check if multiple APNs", value: "multiple-apns", description: "We will use APNs listed in the commitment submitted below." },
        ];
        const { countryList, stateList, cityList, titleOfficers, formDataValue } = this.props;
        // const { fileCount1, fileCount2, fileCount3, totalCount } = this.state;
        // console.log('countrylist=',countryList)
        // console.log('stateList=',stateList)
        // console.log('cityList=',cityList)
        const { upLoadedFiles } = this.state;
        const { handleSubmit, submitting } = this.props;
        let stateSelected = false;
        return (
            <Modal
                isOpen={this.props.isShown}
                toggle={this.props.CloseWorkOrder}
                onClosed={this.props.CloseWorkOrder}
                backdrop={'static'}
                size={'lg'}
                fade={false}
                keyboard={false}
                className={'border-left-modal'}
            >
                <ModalHeader toggle={this.props.CloseWorkOrder}>{this.props && this.props.modalTitle ? this.props.modalTitle : null}</ModalHeader>


                <form name="createWorkRequestForm" onSubmit={handleSubmit(this.titleOfficerFormSubmit)}>
                    <ModalBody>
                        {/* <div className="card">
                            <div className="card-header"><strong>Company</strong> <small>Form</small></div> */}
                        <div className="siteintro">
                            <img src="" />
                            <h1 className="modal-h1">WORK REQUEST FORM</h1>
                            {/* <span className="modal-email">E-mail to: <a href="mailto:info.landinspections@gmail.com">info.landinspections@gmail.com</a></span> */}
                            <span className="modal-email">Inspection report will include the following information if visible and accessible to inspector at the
                                time inspection is performed:
                                Property access, Address verification, Evidence of work started, Improvements, Buildings and
                                structures on PIQ, Percent completion for new-build, Possession, Lease information, Utility
                                and Drainage information, Easement concerns, Encroachment concerns, Boundary structures</span>
                        </div>
                        <div className="card-body">
                            <div className="form group row">
                                <div className="col-md-6" style={{paddingLeft:'0px', marginBottom:'5px'}}>
                                    <label htmlFor="state">State</label>
                                    <select className="form-control" onChange={this.onChangeState}>
                                        <option value="">Please Select</option>
                                        <option value="Arizona">Arizona</option>
                                        <option value="California">California</option>
                                        <option value="Florida">Florida</option>
                                    </select>
                                </div>
                            </div>
                            {this.state.isStateSelected && 
                               ( 
                                    <div>
                                        {/* {SWITCH START} */}
                                        {(() => {
                                            switch (this.state.selectedState) {
                                            case 'Arizona':
                                                return <div>
                                                            <div className="uppersection">
                                                                {this.props.userDetails && this.props.userDetails.user_type == 2 ?
                                                                    <div className="row">
                                                                        <div className="form-group col-md-6">
                                                                            <label htmlFor="company">Officer Responsible</label>
                                                                            <Field component={ReactSelect} name="toofficer" placeholder="Officer Responsible" options={titleOfficers} validate={[required_dropdown]} />
                                                                        </div>
                                                                    </div> : null}
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="dateofrequest">Date Of Request</label>
                                                                                    <Field
                                                                                        id="starttime"
                                                                                        name="dateofrequest"
                                                                                        component={ReactDatePicker}
                                                                                        activeTime={false}
                                                                                        dateTimeFormat={'dd-MMM-yyyy'}
                                                                                        placeholder="Date of request"
                                                                                        className="reactdate form-control"
                                                                                        validate={[requiredDate]}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="apn">Arizona's APN (Assessor's Parcel Number)</label>
                                                                                    <Field component={InputField} name="apn" type="textarea" placeholder="Arizona's APN(s)" />
                                                                                </div>
                                                                            </div>
                                                                            <Field name="multipleapns" component={CheckboxGroup} formDataValue={formDataValue} options={multipleAPNs} />
                                                                            {/* <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="lots">Lot numbers</label>
                                                                                    <Field component={InputField} name="lots" type="text" placeholder="Lot" />
                                                                                </div>
                                                                            </div> */}
                                                                            <div className="form-group">
                                                                                <label htmlFor="addressofpiq">Address of PIQ (if known):</label>
                                                                                <Field component={InputField} name="addressofpiq" type="textarea" placeholder="Address of PIQ (if known)" />
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label htmlFor="county">County</label>
                                                                                <Field component={InputField} name="country" placeholder="County" />
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="gatecode">Gate Code (if required)</label>
                                                                                    <Field component={InputField} name="gatecode" type="text" placeholder="Gate Code" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="company">Company</label>
                                                                                    <Field component={InputField} name="company" placeholder="Company Name" validate={[required]} readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="address">Address</label>
                                                                                    <Field component={InputField} name="address" placeholder="Address" readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="additionalapn">City</label>
                                                                                    <Field component={InputField} name="city" placeholder="City" validate={[required]} readOnly={true} />
                                                                                </div>
                    
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="citystatezip">State</label>
                                                                                    <Field component={InputField} name="state" placeholder="State" validate={[required]} readOnly={true} />
                                                                                </div>
                    
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="citystatezip">Zip</label>
                                                                                    <Field component={InputField} name="zip" placeholder="Zip" validate={[required]} readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="phone">Phone Number</label>
                                                                                    <Field component={InputField} name="phone" placeholder="Phone Number" validate={[required]} readOnly={true} />
                                                                                </div>
                                                                            </div>
                    
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="name">Name</label>
                                                                                    <Field component={InputField} name="name" type="text" placeholder="Name" readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="ordernumber">Order Number</label>
                                                                                    <Field component={InputField} name="ordernumber" placeholder="Order Number" validate={[required]} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                            <div className="lowersection">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        {/* <h2>Property Inspection:</h2>
                    
                                                                        <Field name="propertyinspection" component={CheckboxGroup} options={propertyInspection} />
                    
                                                                        <div className="form-group">
                                                                            <label htmlFor="infoinspector">Additional information for inspector</label>
                                                                            <Field component={InputField} name="infoinspector" type="textarea" placeholder="Additional information for inspector" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="addressofpiq">Address of PIQ (if known):</label>
                                                                            <Field component={InputField} name="addressofpiq" type="textarea" placeholder="Address of PIQ (if known)" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="county">County</label>
                                                                            <Field component={InputField} name="country" placeholder="County" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="recordowner">Recorded Owner(s):</label>
                                                                            <Field component={InputField} name="recordowner" type="text" placeholder="Recorded Owner(s)" />
                                                                        </div> */}
                                                                        <h2>Inspection Turnaround Time Requested</h2>
                                                                        <Field name="servicetype" component={CheckboxGroup} formDataValue={formDataValue} options={serviceType} />
                    
                                                                        <div className="form-group">
                                                                            <label htmlFor="coedate">COE Date:</label>
                                                                            <Field
                                                                                id="starttime"
                                                                                name="coedate"
                                                                                component={ReactDatePicker}
                                                                                activeTime={false}
                                                                                dateTimeFormat={'dd-MMM-yyyy'}
                                                                                placeholder="Coe Date"
                                                                                className="reactdate form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        {/* <h2>Service Type</h2>
                                                                        <Field name="servicetype" component={CheckboxGroup} formDataValue={formDataValue} options={serviceType} />
                    
                                                                        <div className="form-group">
                                                                            <label htmlFor="coedate">COE Date:</label>
                                                                            <Field
                                                                                id="starttime"
                                                                                name="coedate"
                                                                                component={ReactDatePicker}
                                                                                activeTime={false}
                                                                                dateTimeFormat={'dd-MMM-yyyy'}
                                                                                placeholder="Coe Date"
                                                                                className="reactdate form-control"
                                                                            />
                                                                        </div> */}
                                                                        {/* <div className="form-group">
                                                                            <label for="forinspectoruse">For Inspector Use</label>
                                                                            <Field component={InputField} name="inspectoruse" type="textarea" placeholder="For Inspector Use" />
                                                                        </div> */}
                                                                        <div className="form-group">
                                                                            <label htmlFor="infoinspector">Additional information for inspector</label>
                                                                            <Field component={InputField} name="infoinspector" type="textarea" placeholder="Additional information for inspector" />
                                                                        </div>
                                                                    </div>
                    
                                                                </div>
                                                                <div className="row">
                    
                                                                    <div className="col-md-6">
                                                                        <input type="file" id="file" ref="fileUploader" onChange={this.onFileChange} style={{ display: "none" }} />
                                                                        {/* <button type="button" className="btn btn-warning" onClick={this.handleOpenClick} >ADD</button> */}
                                                                        <div className="input-group" onClick={this.handleOpenClick}>
                                                                            <input type="text" className="form-control" value="" placeholder="select File" readOnly />
                                                                            <div className="input-group-prepend">
                                                                                <div className="input-group-text" id="btnGroupAddon2">+</div>
                                                                            </div>
                                                                        </div>
                                                                        <span className="note">You can upload only 1 file at a time</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <ul className="showupfiles">
                                                                            {upLoadedFiles && upLoadedFiles.length ?
                                                                                upLoadedFiles.map((x, i) => {
                                                                                    return (
                                                                                        <li className="file-name" key={i}>
                                                                                            {/* <span>Del</span> */}
                                                                                            {/* <img src={this.getIcon(x)} title={x} height="55" width="55" /> */}
                                                                                            {/* <div className="iname">{x}</div> */}
                                                                                            <i className="fa fa-file"></i> {x}
                                                                                        </li>
                                                                                    )
                                                                                }) : 'No files Uploaded'
                                                                            }
                                                                        </ul>
                    
                                                                    </div>
                                                                </div>
                    
                                                            </div>
                                                        </div>
                                            case 'California':
                                                return <div>
                                                            <div className="uppersection">
                                                                {this.props.userDetails && this.props.userDetails.user_type == 2 ?
                                                                    <div className="row">
                                                                        <div className="form-group col-md-6">
                                                                            <label htmlFor="company">Officer Responsible</label>
                                                                            <Field component={ReactSelect} name="toofficer" placeholder="Officer Responsible" options={titleOfficers} validate={[required_dropdown]} />
                                                                        </div>
                                                                    </div> : null}
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="dateofrequest">Date Of Request</label>
                                                                                    <Field
                                                                                        id="starttime"
                                                                                        name="dateofrequest"
                                                                                        component={ReactDatePicker}
                                                                                        activeTime={false}
                                                                                        dateTimeFormat={'dd-MMM-yyyy'}
                                                                                        placeholder="Date of request"
                                                                                        className="reactdate form-control"
                                                                                        validate={[requiredDate]}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="apn">California's APN (Assessor's Parcel Number California)</label>
                                                                                    <Field component={InputField} name="apn" type="textarea" placeholder="California's APN(s)" />
                                                                                </div>
                                                                            </div>
                                                                            <Field name="multipleapns" component={CheckboxGroup} formDataValue={formDataValue} options={multipleAPNs} />
                                                                            {/* <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="lots">Lot numbers</label>
                                                                                    <Field component={InputField} name="lots" type="text" placeholder="Lot" />
                                                                                </div>
                                                                            </div> */}
                                                                            <div className="form-group">
                                                                                <label htmlFor="addressofpiq">Address of PIQ (if known) California:</label>
                                                                                <Field component={InputField} name="addressofpiq" type="textarea" placeholder="Address of PIQ (if known)" />
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label htmlFor="county">County</label>
                                                                                <Field component={InputField} name="country" placeholder="County" />
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="gatecode">Gate Code (if required)</label>
                                                                                    <Field component={InputField} name="gatecode" type="text" placeholder="Gate Code" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="company">Company</label>
                                                                                    <Field component={InputField} name="company" placeholder="Company Name" validate={[required]} readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="address">Address</label>
                                                                                    <Field component={InputField} name="address" placeholder="Address" readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="additionalapn">City</label>
                                                                                    <Field component={InputField} name="city" placeholder="City" validate={[required]} readOnly={true} />
                                                                                </div>
                    
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="citystatezip">State</label>
                                                                                    <Field component={InputField} name="state" placeholder="State" validate={[required]} readOnly={true} />
                                                                                </div>
                    
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="citystatezip">Zip</label>
                                                                                    <Field component={InputField} name="zip" placeholder="Zip" validate={[required]} readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="phone">Phone Number</label>
                                                                                    <Field component={InputField} name="phone" placeholder="Phone Number" validate={[required]} readOnly={true} />
                                                                                </div>
                                                                            </div>
                    
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="name">Name</label>
                                                                                    <Field component={InputField} name="name" type="text" placeholder="Name" readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="ordernumber">Order Number</label>
                                                                                    <Field component={InputField} name="ordernumber" placeholder="Order Number" validate={[required]} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                            <div className="lowersection">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        {/* <h2>Property Inspection:</h2>
                    
                                                                        <Field name="propertyinspection" component={CheckboxGroup} options={propertyInspection} />
                    
                                                                        <div className="form-group">
                                                                            <label htmlFor="infoinspector">Additional information for inspector</label>
                                                                            <Field component={InputField} name="infoinspector" type="textarea" placeholder="Additional information for inspector" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="addressofpiq">Address of PIQ (if known):</label>
                                                                            <Field component={InputField} name="addressofpiq" type="textarea" placeholder="Address of PIQ (if known)" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="county">County</label>
                                                                            <Field component={InputField} name="country" placeholder="County" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="recordowner">Recorded Owner(s):</label>
                                                                            <Field component={InputField} name="recordowner" type="text" placeholder="Recorded Owner(s)" />
                                                                        </div> */}
                                                                        <h2>Inspection Turnaround Time Requested California</h2>
                                                                        <Field name="servicetype" component={CheckboxGroup} formDataValue={formDataValue} options={serviceType} />
                    
                                                                        <div className="form-group">
                                                                            <label htmlFor="coedate">COE Date:</label>
                                                                            <Field
                                                                                id="starttime"
                                                                                name="coedate"
                                                                                component={ReactDatePicker}
                                                                                activeTime={false}
                                                                                dateTimeFormat={'dd-MMM-yyyy'}
                                                                                placeholder="Coe Date"
                                                                                className="reactdate form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        {/* <h2>Service Type</h2>
                                                                        <Field name="servicetype" component={CheckboxGroup} formDataValue={formDataValue} options={serviceType} />
                    
                                                                        <div className="form-group">
                                                                            <label htmlFor="coedate">COE Date:</label>
                                                                            <Field
                                                                                id="starttime"
                                                                                name="coedate"
                                                                                component={ReactDatePicker}
                                                                                activeTime={false}
                                                                                dateTimeFormat={'dd-MMM-yyyy'}
                                                                                placeholder="Coe Date"
                                                                                className="reactdate form-control"
                                                                            />
                                                                        </div> */}
                                                                        {/* <div className="form-group">
                                                                            <label for="forinspectoruse">For Inspector Use</label>
                                                                            <Field component={InputField} name="inspectoruse" type="textarea" placeholder="For Inspector Use" />
                                                                        </div> */}
                                                                        <div className="form-group">
                                                                            <label htmlFor="infoinspector">Additional information for inspector</label>
                                                                            <Field component={InputField} name="infoinspector" type="textarea" placeholder="Additional information for inspector" />
                                                                        </div>
                                                                    </div>
                    
                                                                </div>
                                                                <div className="row">
                    
                                                                    <div className="col-md-6">
                                                                        <input type="file" id="file" ref="fileUploader" onChange={this.onFileChange} style={{ display: "none" }} />
                                                                        {/* <button type="button" className="btn btn-warning" onClick={this.handleOpenClick} >ADD</button> */}
                                                                        <div className="input-group" onClick={this.handleOpenClick}>
                                                                            <input type="text" className="form-control" value="" placeholder="select File" readOnly />
                                                                            <div className="input-group-prepend">
                                                                                <div className="input-group-text" id="btnGroupAddon2">+</div>
                                                                            </div>
                                                                        </div>
                                                                        <span className="note">You can upload only 1 file at a time</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <ul className="showupfiles">
                                                                            {upLoadedFiles && upLoadedFiles.length ?
                                                                                upLoadedFiles.map((x, i) => {
                                                                                    return (
                                                                                        <li className="file-name" key={i}>
                                                                                            {/* <span>Del</span> */}
                                                                                            {/* <img src={this.getIcon(x)} title={x} height="55" width="55" /> */}
                                                                                            {/* <div className="iname">{x}</div> */}
                                                                                            <i className="fa fa-file"></i> {x}
                                                                                        </li>
                                                                                    )
                                                                                }) : 'No files Uploaded'
                                                                            }
                                                                        </ul>
                    
                                                                    </div>
                                                                </div>
                    
                                                            </div>
                                                        </div>
                                            case 'Florida':
                                                return <div>
                                                            <div className="uppersection">
                                                                {this.props.userDetails && this.props.userDetails.user_type == 2 ?
                                                                    <div className="row">
                                                                        <div className="form-group col-md-6">
                                                                            <label htmlFor="company">Officer Responsible</label>
                                                                            <Field component={ReactSelect} name="toofficer" placeholder="Officer Responsible" options={titleOfficers} validate={[required_dropdown]} />
                                                                        </div>
                                                                    </div> : null}
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="dateofrequest">Date Of Request</label>
                                                                                    <Field
                                                                                        id="starttime"
                                                                                        name="dateofrequest"
                                                                                        component={ReactDatePicker}
                                                                                        activeTime={false}
                                                                                        dateTimeFormat={'dd-MMM-yyyy'}
                                                                                        placeholder="Date of request"
                                                                                        className="reactdate form-control"
                                                                                        validate={[requiredDate]}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="apn">Florida's APN (Assessor's Parcel Number)</label>
                                                                                    <Field component={InputField} name="apn" type="textarea" placeholder="Florida's APN(s)" />
                                                                                </div>
                                                                            </div>
                                                                            <Field name="multipleapns" component={CheckboxGroup} formDataValue={formDataValue} options={multipleAPNs} />
                                                                            {/* <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="lots">Lot numbers</label>
                                                                                    <Field component={InputField} name="lots" type="text" placeholder="Lot" />
                                                                                </div>
                                                                            </div> */}
                                                                            <div className="form-group">
                                                                                <label htmlFor="addressofpiq">Address of PIQ (if known):</label>
                                                                                <Field component={InputField} name="addressofpiq" type="textarea" placeholder="Address of PIQ (if known)" />
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label htmlFor="county">County</label>
                                                                                <Field component={InputField} name="country" placeholder="County" />
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="gatecode">Gate Code (if required)</label>
                                                                                    <Field component={InputField} name="gatecode" type="text" placeholder="Gate Code" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="company">Company</label>
                                                                                    <Field component={InputField} name="company" placeholder="Company Name" validate={[required]} readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="address">Address</label>
                                                                                    <Field component={InputField} name="address" placeholder="Address" readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="additionalapn">City</label>
                                                                                    <Field component={InputField} name="city" placeholder="City" validate={[required]} readOnly={true} />
                                                                                </div>
                    
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="citystatezip">State</label>
                                                                                    <Field component={InputField} name="state" placeholder="State" validate={[required]} readOnly={true} />
                                                                                </div>
                    
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor="citystatezip">Zip</label>
                                                                                    <Field component={InputField} name="zip" placeholder="Zip" validate={[required]} readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="phone">Phone Number</label>
                                                                                    <Field component={InputField} name="phone" placeholder="Phone Number" validate={[required]} readOnly={true} />
                                                                                </div>
                                                                            </div>
                    
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="name">Name</label>
                                                                                    <Field component={InputField} name="name" type="text" placeholder="Name" readOnly={true} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="ordernumber">Order Number</label>
                                                                                    <Field component={InputField} name="ordernumber" placeholder="Order Number" validate={[required]} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                            <div className="lowersection">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        {/* <h2>Property Inspection:</h2>
                    
                                                                        <Field name="propertyinspection" component={CheckboxGroup} options={propertyInspection} />
                    
                                                                        <div className="form-group">
                                                                            <label htmlFor="infoinspector">Additional information for inspector</label>
                                                                            <Field component={InputField} name="infoinspector" type="textarea" placeholder="Additional information for inspector" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="addressofpiq">Address of PIQ (if known):</label>
                                                                            <Field component={InputField} name="addressofpiq" type="textarea" placeholder="Address of PIQ (if known)" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="county">County</label>
                                                                            <Field component={InputField} name="country" placeholder="County" />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="recordowner">Recorded Owner(s):</label>
                                                                            <Field component={InputField} name="recordowner" type="text" placeholder="Recorded Owner(s)" />
                                                                        </div> */}
                                                                        <h2>Inspection Turnaround Time Requested</h2>
                                                                        <Field name="servicetype" component={CheckboxGroup} formDataValue={formDataValue} options={serviceType} />
                    
                                                                        <div className="form-group">
                                                                            <label htmlFor="coedate">COE Date:</label>
                                                                            <Field
                                                                                id="starttime"
                                                                                name="coedate"
                                                                                component={ReactDatePicker}
                                                                                activeTime={false}
                                                                                dateTimeFormat={'dd-MMM-yyyy'}
                                                                                placeholder="Coe Date"
                                                                                className="reactdate form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        {/* <h2>Service Type</h2>
                                                                        <Field name="servicetype" component={CheckboxGroup} formDataValue={formDataValue} options={serviceType} />
                    
                                                                        <div className="form-group">
                                                                            <label htmlFor="coedate">COE Date:</label>
                                                                            <Field
                                                                                id="starttime"
                                                                                name="coedate"
                                                                                component={ReactDatePicker}
                                                                                activeTime={false}
                                                                                dateTimeFormat={'dd-MMM-yyyy'}
                                                                                placeholder="Coe Date"
                                                                                className="reactdate form-control"
                                                                            />
                                                                        </div> */}
                                                                        {/* <div className="form-group">
                                                                            <label for="forinspectoruse">For Inspector Use</label>
                                                                            <Field component={InputField} name="inspectoruse" type="textarea" placeholder="For Inspector Use" />
                                                                        </div> */}
                                                                        <div className="form-group">
                                                                            <label htmlFor="infoinspector">Additional information for inspector</label>
                                                                            <Field component={InputField} name="infoinspector" type="textarea" placeholder="Additional information for inspector" />
                                                                        </div>
                                                                    </div>
                    
                                                                </div>
                                                                <div className="row">
                    
                                                                    <div className="col-md-6">
                                                                        <input type="file" id="file" ref="fileUploader" onChange={this.onFileChange} style={{ display: "none" }} />
                                                                        {/* <button type="button" className="btn btn-warning" onClick={this.handleOpenClick} >ADD</button> */}
                                                                        <div className="input-group" onClick={this.handleOpenClick}>
                                                                            <input type="text" className="form-control" value="" placeholder="select File" readOnly />
                                                                            <div className="input-group-prepend">
                                                                                <div className="input-group-text" id="btnGroupAddon2">+</div>
                                                                            </div>
                                                                        </div>
                                                                        <span className="note">You can upload only 1 file at a time</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <ul className="showupfiles">
                                                                            {upLoadedFiles && upLoadedFiles.length ?
                                                                                upLoadedFiles.map((x, i) => {
                                                                                    return (
                                                                                        <li className="file-name" key={i}>
                                                                                            {/* <span>Del</span> */}
                                                                                            {/* <img src={this.getIcon(x)} title={x} height="55" width="55" /> */}
                                                                                            {/* <div className="iname">{x}</div> */}
                                                                                            <i className="fa fa-file"></i> {x}
                                                                                        </li>
                                                                                    )
                                                                                }) : 'No files Uploaded'
                                                                            }
                                                                        </ul>
                    
                                                                    </div>
                                                                </div>
                    
                                                            </div>
                                                        </div>
                                            default:
                                                return <div style={{paddingLeft:'0px'}}>
                                                    <div className="alert alert-danger text-center">Please select</div>
                                                </div>
                                            }
                                        })()}
                                        {/* {SWITCH END} */}
                                    </div>
                                )
                            }
                            {!this.state.isStateSelected &&
                                <div className="form group row">
                                    <div className="alert alert-danger text-center" style={{width:'100%'}}>
                                        Please select a state
                                    </div>
                                </div>
                            }
                            <div className="footersection">
                                <div className="ftext"><strong>Please Note:</strong>All data fields should be completed to eliminate unnecessary delays. Please include the
                                    commitment and, if available, a plat map and/or survey so we may verify the correct parcel is inspected.</div>
                                <div className="thankyou">Thank you for choosing Land Inspections!</div>
                                <span>www.landinspections.com</span>
                                <span>Phone: (480) 799-2373</span>

                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-success">{submitting ? 'Please wait...' : 'Save'}</button>
                        <button type="button" className="btn btn-warning text-white" onClick={this.props.CloseWorkOrder} >Cancel</button>

                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}
// function processCity(clist) {
//     var city = [];
//     clist.map(x => {
//         var obj = { value: x.id, label: x.city_name }
//         city.push(obj)
//         var obj = {};
//     })
//     return city
// }
const CreateWorkRequestForm = reduxForm({
    form: 'createWorkRequestForm',
    destroyOnUnmount: true,
    enableReinitialize: true,
})(CreateWorkRequest);
const mapStateToProps = (state, props) => {
    const initialValues = props.workOrder;
    const formDataValue =
        state.form.createWorkRequestForm && state.form.createWorkRequestForm.values && state.form.createWorkRequestForm.values.servicetype
            ? state.form.createWorkRequestForm.values.servicetype
            : {};
    // const cityList = state.utility && state.utility.cityList && state.utility.cityList.data && state.utility.cityList.data.length ? processCity(state.utility.cityList.data[0]) : [];
    return {
        initialValues,
        formDataValue
        // cityList
    };
};
export default connect(mapStateToProps)(CreateWorkRequestForm);