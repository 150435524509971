import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { SAGA_ACTIONS, ACTIONS, customDateFormat } from "../../common/config/config";

const propertyInspection = [
    { id: 1, name: "ALTA", value: "alta", description: "Includes the following:address verification, possession, improvements,visual easement check encroachment check,ingress/egress, structures, unrecorded leases,evidence of work started" },
    { id: 2, name: "Foundation Inspection 102.4", value: "foundation-inspection-102.4", description: "" },
    { id: 3, name: "Foundation Endorsement 102.5 (+$10)", value: "foundation-endorsement-102.5-(+$10)", description: "Please provide survey and commitment" },
    { id: 4, name: "Completion Check", value: "completion-check", description: "" },
    { id: 5, name: "Priority Inspection (Mechanic's Lien)", value: "priority-inspection-(mechanic's-lien)", description: "" },
];
const serviceType = [
    { id: 1, name: "STANDARD - ", value: "standard", description: "Three business days" },
    { id: 2, name: "RUSH - ", value: "rush-(+$10)", description: " Completed next business-day by 2pm" },
    { id: 3, name: "SAME DAY INSPECTION - ", value: "super-rush-($20)", description: " Must be submitted by 1pm to allow inspector sufficient travel time." },
    { id: 4, name: "DAY OF CLOSE INSPECTION - ", value: "inspection-on-day-of-close", description: " Completed by 2:00 PM on COE date specified below:" },
];
const multipleAPNs = [
    { id: 1, name: "Check if multiple APNs", value: "multiple-apns", description: "We will use APNs listed in the commitment submitted below." },
];
class ViewWorkOrderRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
        this.showToaster = this.showToaster.bind(this);
    }
    showToaster(type, title, message) {
        this.toastr.add({
            type,
            title,
            position: 'bottom-right',
            attention: true,
            onAttentionClick: id => { },
            message,
            options: { showCloseButton: true },
            showCloseButton: true,
        });
    }

    getPropertyInspection = (data) => {
        if (data) {
            var selectedData = [];
            data.sort();
            data.map(d => {
                var sd = propertyInspection.filter(p => p.id == d)
                if (sd.length) {
                    var obj = { name: sd[0].name, des: sd[0].description };
                    selectedData.push(obj)
                }
            })
            var html = selectedData.map(p => {
                return (
                    <li>
                        <span className="name">{p.name}</span>
                        <span className="des">{p.des}</span>
                    </li>

                )
            })
            return (html)
        }
        else {
            return null
        }
    }
    getColorClass = (id) => {
        if (id == 2) {
            return "rush"
        } else if (id == 3) {
            return "super-rush"
        }
        else {
            return null
        }
    }
    getServiceType = (data) => {
        if (data) {
            var selectedData = [];
            data.sort();
            data.map(d => {
                var sd = serviceType.filter(p => p.id == d)
                if (sd.length) {
                    var obj = { name: sd[0].name, des: sd[0].description, id: sd[0].id };
                    selectedData.push(obj)
                }
            })
            var html = selectedData.map(p => {
                return (
                    <li className={this.getColorClass(p.id)}>
                        <span className="name">{p.name}</span>
                        <span className="des">{p.des}</span>
                    </li>

                )
            })
            return (html)
        }
        else {
            return null
        }
    }

    getIcon = (url) => {
        var ext = url.substr(url.lastIndexOf('.') + 1);
        var file = '';
        switch (ext) {
            case 'jpg':
                file = 'assets/icons/png.png'
                break;
            case 'png':
                file = 'assets/icons/png.png'
                break;
            case 'doc':
                file = 'assets/icons/doc.png'
                break;
            case 'pdf':
                file = 'assets/icons/pdf2.png'
                break;
            case 'docx':
                file = 'assets/icons/doc.png'
                break;
            case 'xlsx':
                file = 'assets/icons/excel.png'
                break;

            default:
                file = 'assets/icons/file.png'
        }
        return (file)
    }


    printfile = (url) => {
        var w = window.open('', 'new div', 'height=400,width=600');
        w.document.write('<html><head>');
        w.document.write('</head><body >');
        w.document.write('<img src="' + url + '"/>');
        w.document.write('</body></html>');
        // w.window.print();
        w.document.close();
        w.focus();
        setTimeout(() => {
            w.print();
        }, 500);
    }

    printPdf = (url) => {
        console.log('print pdf', url);
        // var w = window.open('', 'new div');
        // w.document.write('<html><head>');
        // w.document.write('</head><body >');
        // w.document.write('<object data="' + url + '" type="application/pdf" width="600" height="400"></object>');
        // w.document.write('</body></html>');
        // // w.window.print();
        // w.document.close();
        // w.focus();
        // setTimeout(() => {
        //     w.print();
        // }, 500);
        var mywindow = window.open('', 'new div', 'height=400,width=600');
        mywindow.document.write('<html><head><title></title>');
        mywindow.document.write('</head><body style="background-color:white">');
        mywindow.document.write('<object data="' + url + '" type="application/pdf" width="600" height="400"></object>');
        mywindow.document.write('</body></html>');
        mywindow.document.close();
        mywindow.focus();
        setTimeout(() => {
            mywindow.print();
        }, 1000);
    }


    fileProcess = (data) => {
        console.log('file show', data)

        if (data) {
            console.log('inside con file show', data)
            // Object.getOwnPropertyNames(person).forEach(key =>
            //     console.log(`${key}: ${person[key]}`)


            // );
            // var html = data.map((x,v) => {

            //     return (
            //         <li>
            //             <a href={v} target="_blank" download>yuyu</a>
            //             {/* <a href={x} target="_blank" className="btn btn-warning mt-1" download>files</a> */}
            //         </li>

            //     )
            // })
            var html = Object.keys(data).map(key => {
                return (
                    <li className="file-name">
                        <a href={data[key]} target="_blank" download><i className="fa fa-file"></i> {key}</a>
                        {data[key].substr(data[key].lastIndexOf('.') + 1) == 'pdf' ?
                            <span className="prnt pdf" onClick={() => this.printPdf(data[key])}><i class="fa fa-print"></i></span> :
                            <span className="prnt img" onClick={() => this.printfile(data[key])}><i class="fa fa-print"></i></span>}
                        {/* <a href={x} target="_blank" className="btn btn-warning mt-1" download>files</a> */}
                    </li>

                )
            })
            return (html)
        }
        else {
            return null
        }

    }

    printWorkOrder = () => {
        // console.log('print workorder ');
        // var printContents = document.getElementById('printwoform').innerHTML;
        // var originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        // window.print();
        // document.body.innerHTML = originalContents;
        var printContents = document.getElementById('printwoform').innerHTML;
        var mywindow = window.open('', 'new div', 'height=400,width=600');
        mywindow.document.write('<html><head><title></title>');
        mywindow.document.write('<link rel="stylesheet" href="css/style.css" type="text/css" />');
        mywindow.document.write('<link rel="stylesheet" href="css/print.css" type="text/css" />');
        mywindow.document.write('</head><body style="background-color:white">');
        mywindow.document.write(printContents);
        mywindow.document.write('</body></html>');
        mywindow.document.close();
        mywindow.focus();
        // setTimeout(()=> { mywindow.print(); }, 2000);
        setTimeout(() => {
            mywindow.print();
        }, 1000);
        //   mywindow.close();

        return true;

    }

    componentDidUpdate() {
        // window.onafterprint = () => {
        //     console.log('reload after print');
        //     window.location.reload(true);
        // }
    }
    printIframe = (id) => {
        const iframe = document.frames
            ? document.frames[id]
            : document.getElementById(id);
        const iframeWindow = iframe.contentWindow || iframe;

        iframe.focus();
        iframeWindow.print();

        return false;
    };

    render() {
        const { workOrderData } = this.props;
        return (
            <Modal
                isOpen={this.props.isWorkShown}
                toggle={this.props.CloseViewModal}
                onClosed={this.props.CloseViewModal}
                backdrop={'static'}
                size={'lg'}
                fade={false}
                keyboard={false}
                className={'border-left-modal'}
            >
                <ModalHeader toggle={this.props.CloseViewModal}>{workOrderData && workOrderData.order_number ? 'Order No:' + workOrderData.order_number : null}</ModalHeader>
                <div id="printwoform">
                    <ModalBody className="view-modal">
                        {/* <div className="card">
                            <div className="card-header"><strong>Company</strong> <small>Form</small></div> */}
                        <div class="row mb-3">
                        <div class="col-12 text-center">
                            <img src="assets/img/LOGO_Land_inspections.webp"/>
                            <i class="fa fa-print fa-2x" onClick={this.printWorkOrder} style={{float: 'right',color: '#a75237',marginRight: '5px',cursor:'pointer'}}></i>
                        </div>
                    </div>
                        <div className="siteintro">
                            <img src="" />
                            <span className="pr-icon"><h1 className="modal-h1">WORK REQUEST FORM</h1> </span>
                            {/* <span className="modal-email">E-mail to: <a href="mailto:info.landinspections@gmail.com">info.landinspections@gmail.com</a></span> */}
                            <span className="modal-email">Inspection report will include the following information if visible and accessible to inspector at the
                                time inspection is performed:
                                Property access, Address verification, Evidence of work started, Improvements, Buildings and
                                structures on PIQ, Percent completion for new-build, Possession, Lease information, Utility
                                and Drainage information, Easement concerns, Encroachment concerns, Boundary structures</span>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="state">State</label>
                                    <div className="datavalue">
                                        {workOrderData && workOrderData.reportState}
                                    </div>
                                </div>
                            </div>
                            <div className="uppersection">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="company">Officer Responsible</label>
                                        <div className="datavalue">
                                            {workOrderData && workOrderData.title_officer_details && workOrderData.title_officer_details.name ? workOrderData.title_officer_details.name : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="dateofrequest">Date Of Request</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.date_of_request ? moment(
                                                        `${workOrderData.date_of_request}`
                                                    ).format(customDateFormat) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="apn">APN (Assessor's Parcel Number)</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.apn ? workOrderData.apn : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="addressofpiq">Address of PIQ (if known):</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.address_of_piq ? workOrderData.address_of_piq : null}
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="lots">Lot numbers</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.lot ? workOrderData.lot : null}
                                                </div>
                                            </div>
                                        </div> */}

                                        <div class="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="county">County</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.country ? workOrderData.country : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="gatecode">Gate Code</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.gate_code ? workOrderData.gate_code : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="company">Company</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.company ? workOrderData.company : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="address">Address</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.address ? workOrderData.address : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-4">
                                                <label htmlFor="additionalapn">City</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.city_details && workOrderData.city_details.city_name ? workOrderData.city_details.city_name : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="citystatezip">State</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.state_details && workOrderData.state_details.state_name ? workOrderData.state_details.state_name : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="citystatezip">Zip</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.zipcode ? workOrderData.zipcode : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="phone">Phone Number</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.phone ? workOrderData.phone : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="name">Name</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.name ? workOrderData.name : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="ordernumber">Order Number</label>
                                                <div className="datavalue">
                                                    {workOrderData && workOrderData.order_number ? workOrderData.order_number : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">                            
                                <div className="form-group col-md-6">
                                    <label htmlFor="citystatezip">State</label>
                                    <div className="datavalue">
                                        {workOrderData && workOrderData.state_details && workOrderData.state_details.state_name ? workOrderData.state_details.state_name : null}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="additionalapn">City</label>
                                    <div className="datavalue">
                                        {workOrderData && workOrderData.city_details && workOrderData.city_details.city_name ? workOrderData.city_details.city_name : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="citystatezip">Zip</label>
                                    <div className="datavalue">
                                        {workOrderData && workOrderData.zipcode ? workOrderData.zipcode : null}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="lots">Lot numbers</label>
                                    <div className="datavalue">
                                        {workOrderData && workOrderData.lot ? workOrderData.lot : null}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="gatecode">Gate Code</label>
                                    <div className="datavalue">
                                        {workOrderData && workOrderData.gate_code ? workOrderData.gate_code : null}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="phone">Phone Number</label>
                                    <div className="datavalue">
                                        {workOrderData && workOrderData.phone ? workOrderData.phone : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row"> 
                                <div className="form-group col-md-6">
                                    <label htmlFor="name">Name</label>
                                    <div className="datavalue">
                                        {workOrderData && workOrderData.name ? workOrderData.name : null}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="ordernumber">Order Number</label>
                                    <div className="datavalue">
                                        {workOrderData && workOrderData.order_number ? workOrderData.order_number : null}
                                    </div>
                                </div>
                            </div>*/}
                            </div>
                            <div className="lowersection">
                                <div className="row">
                                    <div className="col-md-6">
                                        {/* <h2>Property Inspection:</h2>
                                        <ul>
                                            {workOrderData && workOrderData.property_inspection && workOrderData.property_inspection.length ? this.getPropertyInspection(workOrderData.property_inspection) : null}

                                        </ul>

                                        <div class="form-group">
                                            <label htmlFor="infoinspector">Additional information for inspector</label>
                                            <div className="datavalue">
                                                {workOrderData && workOrderData.additional_info_for_inspector ? workOrderData.additional_info_for_inspector : null}
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label htmlFor="addressofpiq">Address of PIQ (if known):</label>
                                            <div className="datavalue">
                                                {workOrderData && workOrderData.address_of_piq ? workOrderData.address_of_piq : null}
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label htmlFor="county">County</label>
                                            <div className="datavalue">
                                                {workOrderData && workOrderData.country ? workOrderData.country : null}
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label htmlFor="recordowner">Recorded Owner(s):</label>
                                            <div className="datavalue">
                                                {workOrderData && workOrderData.recorded_owners ? workOrderData.recorded_owners : null}
                                            </div>
                                        </div> */}
                                        <h2>Inspection Turnaround Time Requested</h2>
                                        <ul>
                                            {workOrderData && workOrderData.service_type && workOrderData.service_type.length ? this.getServiceType(workOrderData.service_type) : null}

                                        </ul>

                                        <div class="form-group">
                                            <label htmlFor="coedate">COE Date:</label>
                                            <div className="datavalue">
                                                {workOrderData && workOrderData.coe_date ? moment(
                                                    `${workOrderData.coe_date}`
                                                ).format(customDateFormat) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div class="form-group">
                                            <label htmlFor="infoinspector">Additional information for inspector</label>
                                            <div className="datavalue">
                                                {workOrderData && workOrderData.additional_info_for_inspector ? workOrderData.additional_info_for_inspector : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="files">Uploaded Files</label>
                                        <ul className="showupfiles">
                                            {workOrderData && workOrderData.uploaded_files ? this.fileProcess(workOrderData.uploaded_files) : null}
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="footersection">
                                <div className="ftext"><strong>Please Note:</strong>All data fields should be completed to eliminate unnecessary delays. Please include the
                                    commitment and, if available, a plat map and/or survey so we may verify the correct parcel is inspected.</div>
                                <div className="thankyou">Thank you for choosing Land Inspections!</div>
                                <span>www.landinspections.com</span>
                                <span>Phone: (480) 799-2373</span>

                            </div>


                        </div>

                    </ModalBody>
                </div>
                <ModalFooter>
                    <button type="button" className="btn btn-warning text-white mr-2" onClick={this.printWorkOrder} ><i class="fa fa-print"></i></button>
                    <button type="button" className="btn btn-warning text-white" onClick={this.props.CloseViewModal} >Cancel</button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = (state, props) => {

    return {

    };
};
export default connect(mapStateToProps)(ViewWorkOrderRequest);