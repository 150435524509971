import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import axios from 'axios';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { ACTIONS, APIS, STORAGE } from './common/config/config';
// import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
const token = localStorage.getItem(STORAGE.USER_TOKEN);

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <div className="height-full">
        <ReduxToastr
          timeOut={3000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <App />
      </div>
    </Provider>,
    document.getElementById('root'),
  );
}

if (token) {
  try {
    axios({
      method: 'POST',
      url: APIS.USER.ME,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(success => {
        console.log('success data of me',success)
        store.dispatch({
          type: ACTIONS.USER.ME,
          payload: success.data,
        });
        render();
      })
      .catch(error => {
        localStorage.removeItem(STORAGE.USER_TOKEN);
        store.dispatch({
          type: ACTIONS.USER.LOGOUT,
        });
        render();
      });
  } catch (e) {
    localStorage.removeItem(STORAGE.USER_TOKEN);
    render();
  }
} else {
  render();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
