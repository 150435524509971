import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import FriendList from './friendList';
import { SAGA_ACTIONS, ACTIONS } from "../../common/config/config";
import { data } from 'jquery';
class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatMsg: '',
      currPage: 1
    };
    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
    this.showToaster = this.showToaster.bind(this);
  }

  // componentWillMount(){
  //   console.log(' message friend list called')
  // }
  showToaster(type, title, message) {
    this.toastr.add({
      type,
      title,
      position: 'top-left',
      attention: true,
      onAttentionClick: id => { },
      message,
      options: { showCloseButton: true },
      showCloseButton: true,
    });
  }
  dateProcess = (data) => {

    var localTime = moment.utc(data).toDate();
    localTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
    var formatDate = moment(localTime).calendar();

    // var formatDate = moment(data).calendar(); //previous code
    return (formatDate)

  }

  handleMessage = (e) => {
    this.setState({ chatMsg: e.target.value })
    console.log('handleMessage', e.target.value);
  }

  handleSend = () => {
    // this.props.dispatch({
    //   type: ACTIONS.MESSAGES.EMPTY_MSG_DETAILS_WITH_PARTICULAR_FRND,
    //   payload: null,
    // });
    if (this.state.chatMsg.trim()) {
      console.log('handleSend', this.state.chatMsg)
      var params = {
        sent_by: this.props.meDetails && this.props.meDetails.id ? this.props.meDetails.id : null,
        sent_to: this.props.selectedFriendId && this.props.selectedFriendId.selectedFriend ? this.props.selectedFriendId.selectedFriend : null,
        order_id: this.props.selectedFriendId && this.props.selectedFriendId.order_id ? this.props.selectedFriendId.order_id : null,
        message: this.state.chatMsg,
      }
      return new Promise((resolve, reject) => {
        this.props.dispatch({
          type: SAGA_ACTIONS.MESSAGES.SAVE_OR_SEND_MSG,
          payload: params,
          callbackSuccess: (resp) => {
            this.setState({ chatMsg: '' })
            this.getChatDetails(1);
            console.log('resp', resp);
          },
          callbackError: e => {
            console.log('error', e)
            reject(e);
          },
        });
        return resolve(true);
      });
    } else {
      this.showToaster('error', 'Error', 'Please type something');
    }
  }


  onEnterPress = (e) => {
    console.log('key enter in text box');
    if (e.keyCode == 13 && e.shiftKey == false) {
      console.log('enter key press in text box');


      this.handleSend()



    }
  }




  componentWillReceiveProps(nextProps) {
    console.log('nextProps', nextProps);
    console.log('outside-ang', nextProps.currentPage)
    if (nextProps && nextProps.currentPage == 1) {
      setTimeout(() => {
        console.log('inside-ang', nextProps.currentPage);
        var objDiv = document.getElementById("cmessages");
        console.log('scrollHeight', objDiv.scrollHeight)
        objDiv.scrollTop = objDiv.scrollTop + objDiv.scrollHeight;
      }, 200);
    }

  }

  componentWillUnmount() {
    this.props.dispatch({
      type: ACTIONS.MESSAGES.EMPTY_MSG_DETAILS_WITH_PARTICULAR_FRND,
      payload: null,
    });
  }

  getChatDetails = (curPage = 1) => {
    console.log('call chat details')
    var param = {
      me_user: this.props.meDetails && this.props.meDetails.id ? this.props.meDetails.id : null,
      friend_user: this.props.selectedFriendId && this.props.selectedFriendId.selectedFriend ? this.props.selectedFriendId.selectedFriend : null,
      order_id: this.props.selectedFriendId && this.props.selectedFriendId.order_id ? this.props.selectedFriendId.order_id : null,
      page: curPage,
    }
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: SAGA_ACTIONS.MESSAGES.GET_MSG_WITH_PARTICULAR_FRND,
        payload: param,
        callbackSuccess: (resp) => {
          console.log('resp', resp);
        },
        callbackError: e => {
          console.log('error', e)
          reject(e);
        },
      });
      return resolve(true);
    });
  }

  handleSCroll = (e) => {
    var myElement = document.getElementById('cmessages');
    var topPos = myElement.scrollTop;
    if (this.props.currentPage != this.props.lastPage) {
      if (topPos === 0) {
        let cPage = this.props.currentPage + 1;
        this.getChatDetails(cPage);
        console.log(' callloadmore data');
        myElement.scrollTop = myElement.scrollTop + 150;
      }
      else {
        console.log('normal')
      }
    }
  }

  handleOpenClick = (e) => {
    this.refs.fileUploader.click();
  }

  onFileChange = (e) => {


    // var params = {
    //   sent_by: this.props.meDetails && this.props.meDetails.id ? this.props.meDetails.id : null,
    //   sent_to: this.props.selectedFriendId && this.props.selectedFriendId.selectedFriend ? this.props.selectedFriendId.selectedFriend : null,
    //   order_id: this.props.selectedFriendId && this.props.selectedFriendId.order_id ? this.props.selectedFriendId.order_id : null,
    //   message: this.state.chatMsg,
    // }

    console.log('uploaedfile', e.target.files[0])
    let formData = new FormData();
    formData.append('files[0]', e.target.files[0]);
    formData.append('sent_to', this.props.selectedFriendId && this.props.selectedFriendId.selectedFriend ? this.props.selectedFriendId.selectedFriend : null);
    formData.append('order_id', this.props.selectedFriendId && this.props.selectedFriendId.order_id ? this.props.selectedFriendId.order_id : null);

    return new Promise((resolve, reject) => {
      this.props.dispatch({
          type: SAGA_ACTIONS.MESSAGES.FILE_SEND_OVER_MESSAGE,
          payload: {
              formData
          },
          callbackSuccess: (resp) => {
            this.getChatDetails(1);
          },
          callbackError: (e) => {
           
            this.showToaster('error', 'Invalid File Type', e.data.message);
            console.log('error', e)
            reject(e);
          },
      });
      return resolve(true);
  });
    

  }

  render() {
    const { messages } = this.props;

    console.log('messages==>', messages);
    return (

      <div>
        <Sidebar />
        <div className="c-wrapper c-fixed-components">
          <Header pageTitle={"Messages"} />
          <div className="c-body">
            <main className="c-main">
              <div className="container-fluid">
                <div className="fade-in">

                  <div className="row">
                    <div className="col-sm-12">
                      <div className=" messaging msg-with-list">
                        <div className="inbox_msg">

                          <div className="inbox_people">
                            <div className="headind_srch">
                              <div className="recent_heading">
                                <h4>Recent Messages</h4>
                              </div>
                              {/* <div className="srch_bar">
                                <div className="stylish-input-group">
                                  <input type="text" className="search-bar" placeholder="Search" />
                                  <span className="input-group-addon">
                                    <button type="button"> <i className="fa fa-search" aria-hidden="true" /> </button>
                                  </span> </div>
                              </div> */}
                            </div>
                            <FriendList />
                          </div>

                          <div className="mesgs">
                            <div className="msg_history" id="cmessages" onScroll={this.handleSCroll}>
                              {/* <div style={cstyle}> */}



                              {messages && messages && messages.length ? (
                                messages.map((message, i) => {
                                  return (
                                    message.sent_by != this.props.meDetails.id ?
                                      <div key={i} className="incoming_msg">
                                        <div className="incoming_msg_img"> <img src={message && message.profile_pic ? message.profile_pic : 'https://ptetutorials.com/images/user-profile.png'} alt="sunil" /> </div>
                                        <div className="received_msg">
                                          <div className="received_withd_msg">
                                            {message.type == 0 ?
                                              <p>{message.message}</p> : null}
                                            {message.type == 1 ?
                                              <p><a href={message.file_path + '/' + message.message} target="_blank"><img src={message.file_path + '/' + message.message} height="75px" width="150px" /></a></p> : null}
                                            {message.type == 2 ?
                                              <p><a href={message.file_path + '/' + message.message} target="_blank"><img src="assets/icons/pdfx.png" height="75px" width="75px" /></a></p> : null}
                                            <span className="time_date">{this.dateProcess(message.created_at)}
                                              {/* {message && message.order_details&&message.order_details.order_number? <span className="withordr"><i class="fa fa-book"></i>{message.order_details.order_number}</span>:null} */}
                                            </span>

                                          </div>
                                        </div>
                                      </div> :
                                      <div className="outgoing_msg">
                                        <div className="sent_msg">
                                          {message.type == 0 ?
                                            <p>{message.message}</p> : null}
                                          {message.type == 1 ?
                                            <p><a href={message.file_path + '/' + message.message} target="_blank"><img src={message.file_path + '/' + message.message} height="75px" width="150px" /></a></p> : null}
                                          {message.type == 2 ?
                                            <p><a href={message.file_path + '/' + message.message} target="_blank"><img src="assets/icons/pdfx.png" height="75px" width="75px" /></a></p> : null}

                                          <span className="time_date">{this.dateProcess(message.created_at)}
                                            {/* {message && message.order_details&&message.order_details.order_number? <span className="withordr"><i class="fa fa-book"></i>{message.order_details.order_number}</span>:null} */}
                                          </span>

                                        </div>
                                      </div>

                                  );
                                })
                              ) : (
                                  <h5 className="text-center">No chat history found</h5>
                                )}

                              {/* </div> */}
                            </div>
                            {this.props.selectedFriendId ?
                              <div className="type_msg">
                                <div className="input_msg_write">
                                  <input type="text" className="write_msg" value={this.state.chatMsg} placeholder="Type a message" onKeyDown={this.onEnterPress} onChange={this.handleMessage} />
                                  <input type="file" id="file" ref="fileUploader" onChange={this.onFileChange} accept="image/jpg, image/jpeg, image/png, application/pdf" style={{ display: "none" }} />
                                  <button className="msg-attach" onClick={this.handleOpenClick} type="button"><i class="fa fa-paperclip"></i></button>
                                  <button className="msg_send_btn" type="button" onClick={this.handleSend}><i className="fa fa-paper-plane-o" aria-hidden="true" /></button>
                                </div>
                              </div> : null}

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.col*/}


                  </div>

                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = state => {
  // const messages = state.message && state.message.messageDetails && state.message.messageDetails.data && state.message.messageDetails.data.length && state.message.messageDetails.data[0] && state.message.messageDetails.data[0].data && state.message.messageDetails.data[0].data.length ? state.message.messageDetails.data[0].data : [];
  const messages = state.message && state.message.messageDetails && state.message.messageDetails.dataset ? state.message.messageDetails.dataset : [];
  const currentPage = state.message && state.message.messageDetails && state.message.messageDetails.current_page ? state.message.messageDetails.current_page : null;
  const lastPage = state.message && state.message.messageDetails && state.message.messageDetails.last_page ? state.message.messageDetails.last_page : null;
  const meDetails = state.user && state.user.user && state.user.user.data && state.user.user.data.length ? state.user.user.data[0] : null;
  const selectedFriendId = state.message && state.message.selectedFriend ? state.message.selectedFriend : null;
  return {
    messages,
    meDetails,
    selectedFriendId,
    currentPage,
    lastPage
  };
};

export default connect(mapStateToProps)(Messages);