import axios from 'axios';
import { STORAGE } from '../common/config/config';

export class CallApi {
  static GET(url, params, isAuth) {
    return new Promise((resolve, reject) => {
      let headers = {};
      if (isAuth) {
        const token = localStorage.getItem(STORAGE.USER_TOKEN);
        headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      return axios({
        method: 'GET',
        url,
        headers,
        params,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          console.log('inside axios api call',error);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem(STORAGE.USER_TOKEN)
            window.location.href = "/login";
          }
          return reject(error.response);
        });
    });
  }

  static POST(url, data, isAuth) {
    return new Promise((resolve, reject) => {
      let headers = {};
      if (isAuth) {
        const token = localStorage.getItem(STORAGE.USER_TOKEN);
        headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      return axios({
        method: 'POST',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          console.log('400 error', error);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem(STORAGE.USER_TOKEN)
            window.location.href = "/login";
          }
          return reject(error.response);
        });
    });
  }

  static PUT(url, data, isAuth) {
    return new Promise((resolve, reject) => {
      let headers = {};
      if (isAuth) {
        const token = localStorage.getItem(STORAGE.USER_TOKEN);
        headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      return axios({
        method: 'PUT',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem(STORAGE.USER_TOKEN)
            window.location.href = "/login";
          }
          return reject(error.response);
        });
    });
  }

  static DELETE(url, data, isAuth) {
    return new Promise((resolve, reject) => {
      let headers = {};
      if (isAuth) {
        const token = localStorage.getItem(STORAGE.USER_TOKEN);
        headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      return axios({
        method: 'DELETE',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem(STORAGE.USER_TOKEN)
            window.location.href = "/login";
          }
          return reject(error.response);
        });
    });
  }

  static POST_FORM_DATA(url, data, isAuth) {
    return new Promise((resolve, reject) => {
      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      };
      if (isAuth) {
        const token = localStorage.getItem(STORAGE.USER_TOKEN);
        headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        };
      }
      return axios({
        method: 'POST',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem(STORAGE.USER_TOKEN)
            window.location.href = "/login";
          }
          return reject(error.response);
        });
    });
  }
  static FILEUPLOAD(url, data, isAuth) {
    return new Promise((resolve, reject) => {
      let headers = {};
      if (isAuth) {
        const token = localStorage.getItem(STORAGE.USER_TOKEN);
        headers = {
          Authorization: `Bearer ${token}`,
          'content-type': 'multipart/form-data',
        };
      }
      return axios({
        method: 'POST',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem(STORAGE.USER_TOKEN)
            window.location.href = "/login";
          }
          return reject(error.response);
        });
    });
  }
}
