import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import userReducer from './userReducer';
import workOrderReducer from './workorderReducer';
import officersReducer from './officersReducer';
import utilityReducer from './utilityReducer';
import loaderReducer from './loaderReducer';
import messageReducer from './messageReducer';

const rootReducer = combineReducers({
  form: formReducer.plugin({
    "editRoleForm": (state, action) => {
      switch (action.type) {
        case "@@router/LOCATION_CHANGE":
          return undefined;
        default:
          return state;
      }
    }
  }),
  user: userReducer,
  toastr: toastrReducer,
  loader: loaderReducer,
  workorder:workOrderReducer,
  officer:officersReducer,
  utility:utilityReducer,
  form: formReducer,
  message: messageReducer,
});

export default rootReducer;
