import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, NavLink, Link } from "react-router-dom";
import { ACTIONS, SAGA_ACTIONS } from '../../common/config/config';
import $ from "jquery";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    this.getAllUnreadMsgs()

  }

  getAllUnreadMsgs = () => {
    this.props.dispatch({
        type: SAGA_ACTIONS.MESSAGES.GET_ALL_CHAT_COUNT,
    });
}

  render() {
    const { userDetails } = this.props;
    return (
      <div className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show" id="sidebar">
        <div className="c-sidebar-brand d-lg-down-none">
          {/* <svg className="c-sidebar-brand-full" width={118} height={46} alt="CoreUI Logo">
            <use xlinkHref="assets/brand/coreui.svg#full" />
          </svg>
          <svg className="c-sidebar-brand-minimized" width={46} height={46} alt="CoreUI Logo">
            <use xlinkHref="assets/brand/coreui.svg#signet" />
          </svg> */}
          <img alt="Logo" src="assets/img/logo-small.png" />
        </div>
        <ul className="c-sidebar-nav">
          <li className="c-sidebar-nav-item">
            <NavLink className="c-sidebar-nav-link" to="/dashboard" activeClassName="setactive">
              {/* <svg className="c-sidebar-nav-icon">
                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-speedometer" />
              </svg>  */}
              <i className="fa fa-tachometer" aria-hidden="true"></i> Dashboard
            </NavLink>
          </li>
          {/* <li className="c-sidebar-nav-title">Theme</li> */}
          {userDetails && userDetails.user_type == 2 ?
            <li className="c-sidebar-nav-item">
              <NavLink className="c-sidebar-nav-link" to="/title-officers" activeClassName="setactive">
                {/* <svg className="c-sidebar-nav-icon">
                  <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-drop1" />
                </svg>  */}
               <i className="fa fa-user" aria-hidden="true"></i>  Title Officers
            </NavLink>
            </li> : null}
          <li className="c-sidebar-nav-item">
            <NavLink className="c-sidebar-nav-link" to="/work-orders" activeClassName="setactive">
              {/* <svg className="c-sidebar-nav-icon">
                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-drop1" />
              </svg>  */}
             <i className="fa fa-tasks" aria-hidden="true"></i> Work Orders
            </NavLink>
          </li>
          <li className="c-sidebar-nav-item">
            <NavLink className="c-sidebar-nav-link" to="/reports" activeClassName="setactive">
              {/* <svg className="c-sidebar-nav-icon">
                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-pencil" />
              </svg>  */}
              <i className="fa fa-file" aria-hidden="true"></i>Reports
              </NavLink>
          </li>
          {userDetails && userDetails.user_type == 3 ?
            <li className="c-sidebar-nav-item">
              <NavLink className="c-sidebar-nav-link" to="/leave-settings" activeClassName="setactive">
                {/* <svg className="c-sidebar-nav-icon">
                  <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-pencil" />
                </svg> Leave Settings */}
                <i className="fa fa-cog" aria-hidden="true"></i>Leave Settings
              </NavLink>
            </li> : null}
          <li className="c-sidebar-nav-item">
            {/* <a className="c-sidebar-nav-link" href="typography.html"> */}
            <NavLink className="c-sidebar-nav-link" to="/user-profile" activeClassName="setactive">
              {/* <svg className="c-sidebar-nav-icon">
                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-pencil" />
              </svg>  */}
              <i className="fa fa-user" aria-hidden="true"></i>My Account
              </NavLink>
            {/* </a> */}
          </li>
          <li className="c-sidebar-nav-item">
            <NavLink className="c-sidebar-nav-link" to="/messages" activeClassName="setactive">
              {/* <svg className="c-sidebar-nav-icon">
                <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-pencil" />
              </svg> Messages */}
              <i className="fa fa-paper-plane" aria-hidden="true"></i>Messages {this.props.unreadMsgs?<span className="tunread-count">{this.props.unreadMsgs}</span>:null}
               </NavLink>
          </li>


        </ul>
        {/* <button className="c-sidebar-minimizer c-className-toggler" type="button" data-target="_parent" data-className="c-sidebar-minimized" /> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const userDetails = state.user && state.user.user && state.user.user.data && state.user.user.data.length ? state.user.user.data[0] : null;
  const unreadMsgs = state.message && state.message.totalUnreadMsgs &&state.message.totalUnreadMsgs.data?state.message.totalUnreadMsgs.data:null;
  return {
    userDetails,
    unreadMsgs
  };
};
export default connect(mapStateToProps)(withRouter(Sidebar));
