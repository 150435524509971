import React, { Component } from 'react';
import { connect } from "react-redux";
import Switch from "react-switch";
import ReactPaginate from 'react-paginate';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import Select from 'react-select'
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { confirmAlert } from 'react-confirm-alert';
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import { SAGA_ACTIONS, ACTIONS,customDateFormat } from "../../common/config/config";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DatePicker from "react-datepicker";
class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titleOfficer: '',
            ordernumber: '',
            pageNumber: 0,
            isShown: false,
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            setRange: '',
            checkoutStartDate: '',
            checkoutEndDate: '',
            completedstartDate: moment().subtract(7, 'days'),
            completedendDate: moment(),
            setCompletedRange: '',
            setCompletedStartDate: '',
            setCompletedEndDate: '',

        };
        this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
        this.showToaster = this.showToaster.bind(this);
    }

    showToaster(type, title, message) {
        this.toastr.add({
            type,
            title,
            position: 'top-left',
            attention: true,
            onAttentionClick: id => { },
            message,
            options: { showCloseButton: true },
            showCloseButton: true,
        });
    }
    componentWillMount() {
        this.getAllReport();
        this.getAlltitleOfficers();
    }
    getAllReport = (titleOfficerId = null, orderNo = null, fromdate = null, todate = null, comstdate = null, comenddate = null, page = 1) => {
        this.props.dispatch({
            type: SAGA_ACTIONS.UTILITY.GET_ALL_REPORT,
            payload: {
                page: page,
                title_off_id: titleOfficerId? titleOfficerId.value : null,
                oreder_number: orderNo,
                fromDate: fromdate,
                toDate: todate,
                completedStartDate: comstdate,
                completedEndDate: comenddate,
            },
        });
    }

    getAlltitleOfficers = () => {
        this.props.dispatch({
            type: SAGA_ACTIONS.TITLEOFFICERS.TITLE_OFFICERS_LIST,
        });
    }

    handleEvent = (event, picker) => {
        this.setState({ setRange: moment(picker.startDate).format(customDateFormat) + ' - ' + moment(picker.endDate).format(customDateFormat), startDate: picker.startDate, endDate: picker.endDate });
        this.setState({ checkoutStartDate: moment(picker.startDate).format(customDateFormat), checkoutEndDate: moment(picker.endDate).format(customDateFormat) })
    }

    handleCompletedEvent = (event, picker) => {
        this.setState({ setCompletedRange: moment(picker.startDate).format(customDateFormat) + ' - ' + moment(picker.endDate).format(customDateFormat), completedstartDate: picker.startDate, completedendDate: picker.endDate });
        this.setState({ setCompletedStartDate: moment(picker.startDate).format(customDateFormat), setCompletedEndDate: moment(picker.endDate).format(customDateFormat) })
    }

    handlePageClick = e => {
        this.setState({ pageNumber: e.selected });
        var titleOfficer = this.state.titleOfficer ? this.state.titleOfficer : null;
        var fromDate = this.state.checkoutStartDate;
        var toDate = this.state.checkoutEndDate;
        var comstdate = this.state.setCompletedStartDate;
        var comenddate = this.state.setCompletedEndDate;
        this.getAllReport(titleOfficer, this.state.ordernumber, fromDate, toDate, comstdate, comenddate, e.selected + 1);
    };

    handleChange = (titleOfficer) => {
        this.setState({ titleOfficer: titleOfficer });
    }

    handleOrderNumber = (e) => {
        this.setState({
            ordernumber: e.target.value
        })
    }

    filterResult = () => {
        this.setState({ pageNumber: 0 });
        var titleOfficer = this.state.titleOfficer ? this.state.titleOfficer : null;
        var fromDate = this.state.checkoutStartDate;
        var toDate = this.state.checkoutEndDate;
        var comstdate = this.state.setCompletedStartDate;
        var comenddate = this.state.setCompletedEndDate;
        var page = 1;
        this.getAllReport(titleOfficer, this.state.ordernumber, fromDate, toDate, comstdate, comenddate, page);
    }
    resetResult = () => {
        this.setState({ pageNumber: 0 });
        this.setState({
            checkoutStartDate: '', checkoutEndDate: '', startDate: moment().subtract(7, 'days'),
            endDate: moment(), setRange: '', titleOfficer: null, ordernumber: '',
            setCompletedStartDate: '', setCompletedEndDate: '',
            completedstartDate: moment().subtract(7, 'days'),
            completedendDate: moment(),
            setCompletedRange: '',
        })
        this.getAllReport();
    }


    viewOrDownloadWorkReport = (report, action) => {
        console.log('action', action)
        console.log('report', report);
        var params = {
            id: report.report_id
        }
        return new Promise((resolve, reject) => {
            this.props.dispatch({
                type: SAGA_ACTIONS.UTILITY.REPORT_VIEW_OR_DOWNLOAD,
                payload: params,
                callbackSuccess: (resp) => {
                    console.log('resp', resp)
                    console.log('view url', resp.data.data.static_url)
                    if (resp && resp.data && resp.data.data && resp.data.data.static_url && action == 'view') {
                        window.open(resp.data.data.static_url, "_blank");
                    }
                    if (resp && resp.data && resp.data.data && resp.data.data.url && action == 'download') {
                        window.open(resp.data.data.url);
                        // this.download_file(resp.data.data.url,'sample')
                    }

                },
                callbackError: e => {
                    this.showToaster('error', 'Error', e.data.message);
                    reject(e);
                },
            });
            return resolve(true);
        });

    }

    render() {

        return (
            <div>
                <Sidebar />
                <div className="c-wrapper c-fixed-components">
                    <Header pageTitle={"Reports"} />
                    <div className="c-body">
                        <main className="c-main">
                            <div className="container-fluid">
                                <div className="fade-in">
                                    <div className="row vertical-center">

                                        <div className="col-2">
                                            <div className="form-group form-focus">
                                                <label className="focus-label">TITLE OFFICER</label>
                                                <div className="cal-icon react-cus-dropdown">
                                                    <Select
                                                        value={this.state.titleOfficer}
                                                        onChange={this.handleChange}
                                                        classNamePrefix="my-menu"
                                                        options={this.props.titleOfficers}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group form-focus">
                                                <label className="focus-label">Created Date</label>
                                                <div className="cal-icon">
                                                    <DateRangePicker
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        onEvent={this.handleEvent}
                                                        maxDate={moment()}
                                                        ranges={{
                                                            '7 Days': [moment().subtract(7, 'days'), moment()],
                                                        }
                                                        }
                                                    >
                                                        <input type="text" className="form-control floating" value={this.state.setRange} />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group form-focus">
                                                <label className="focus-label">Order Number</label>
                                                <input type="text" id="ordernumber" className="form-control floating" name="ordernumber" value={this.state.ordernumber} onChange={this.handleOrderNumber} />
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group form-focus">
                                                <label className="focus-label">Completed Date</label>
                                                <div className="cal-icon">
                                                    <DateRangePicker
                                                        startDate={this.state.completedstartDate}
                                                        endDate={this.state.completedendDate}
                                                        onEvent={this.handleCompletedEvent}
                                                        maxDate={moment()}
                                                        ranges={{
                                                            '7 Days': [moment().subtract(7, 'days'), moment()],
                                                        }
                                                        }
                                                    >
                                                        <input type="text" className="form-control floating" value={this.state.setCompletedRange} />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 mt-adjust">
                                            <button type="button" className="btn btn-success btn-block" onClick={this.filterResult}> Search </button>
                                        </div>
                                        <div className="col-2 mt-adjust">
                                            <button type="button" className="btn btn-warning btn-block text-white" onClick={this.resetResult}> Reset </button>
                                        </div>


                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header"><i className="fa fa-align-justify" /> Reports</div>
                                                <div className="card-body">
                                                    <table className="table table-responsive-sm table-bordered table-striped table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th>Order Number</th>
                                                                <th>Title Officers</th>
                                                                <th>Submited On</th>
                                                                <th>Completed On</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.props && this.props.workReportList && this.props.workReportList.length ? (
                                                                this.props.workReportList.map(report => {
                                                                    return (
                                                                        <tr key={report.id}>
                                                                            <td>
                                                                                {report && report.order_details && report.order_details.order_number ? report.order_details.order_number : null}
                                                                            </td>
                                                                            <td>
                                                                                {report && report.title_officer_details && report.title_officer_details.name ? report.title_officer_details.name : null}
                                                                            </td>
                                                                            <td>
                                                                                {moment(
                                                                                    `${report.created_at}`
                                                                                ).format(customDateFormat)}
                                                                            </td>

                                                                            <td>
                                                                                {report && report.report_details && report.report_details.completed_on ? moment(
                                                                                    `${report.report_details.completed_on}`
                                                                                ).format(customDateFormat) : null}
                                                                            </td>
                                                                            <td className="view files">
                                                                                <span className="mr-2" onClick={() => this.viewOrDownloadWorkReport(report, 'view')}><i className="fa fa-eye" aria-hidden="true"></i></span>
                                                                                <span onClick={() => this.viewOrDownloadWorkReport(report, 'download')}><i className="fa fa-download" aria-hidden="true"></i></span>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                    <tr>
                                                                        <td colSpan={70} className="no-record">
                                                                            no record
                              </td>
                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </table>
                                                    <nav>
                                                        {this.props && this.props.totalNoOfWorkReport ?
                                                            <div style={{ textAlign: 'center' }}>
                                                                <ReactPaginate
                                                                    previousLabel={'Prev'}
                                                                    nextLabel={'Next'}
                                                                    breakLabel="..."
                                                                    breakClassName="break-me"
                                                                    pageCount={Math.ceil(this.props.totalNoOfWorkReport / 10)}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={this.handlePageClick}
                                                                    containerClassName="pagination"
                                                                    subContainerClassName="pages pagination"
                                                                    activeClassName="active"
                                                                    pageClassName="page-item"
                                                                    pageLinkClassName="page-link"
                                                                    previousClassName="page-item"
                                                                    nextClassName="page-item"
                                                                    previousLinkClassName="page-link"
                                                                    nextLinkClassName="page-link"
                                                                    forcePage={this.state.pageNumber}
                                                                />
                                                            </div> : null}
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /.col*/}
                                    </div>
                                    {/* /.row*/}
                                </div>
                            </div>
                        </main>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
function processtitleOfficers(tolist, userDetails) {

    if (userDetails.user_type != 3) {
        var tofflist = [{ value: userDetails.id, label: 'Myself' }];
    } else {
        var tofflist = [];
    }
    tolist.map(x => {
        var obj = { value: x.id, label: x.name }
        tofflist.push(obj)
        var obj = {};
    })
    return tofflist
}
const mapStateToProps = state => {
    const userDetails = state.user && state.user.user && state.user.user.data && state.user.user.data.length ? state.user.user.data[0] : null;
    const workReportList = state.utility && state.utility.allReport && state.utility.allReport.data && state.utility.allReport.data.length && state.utility.allReport.data[0] && state.utility.allReport.data[0].data ? state.utility.allReport.data[0].data : [];
    const totalNoOfWorkReport = state.utility && state.utility.allReport && state.utility.allReport.data && state.utility.allReport.data.length && state.utility.allReport.data[0] && state.utility.allReport.data[0].data ? state.utility.allReport.data[0].total : 0;
    const titleOfficers = userDetails && state.officer && state.officer.titleOfficersList && state.officer.titleOfficersList.data && state.officer.titleOfficersList.data.length ? processtitleOfficers(state.officer.titleOfficersList.data[0], userDetails) : [];
    return {
        workReportList,
        totalNoOfWorkReport,
        titleOfficers
    };
};

export default connect(mapStateToProps)(Report);