import React from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { emailValidator, required, phoneValidator, required_dropdown } from '../../common/validators/form-validators';
import { InputField, ReactSelect, ReactRegSelect } from '../../common/form-elements/elements';
import { ACTIONS, SAGA_ACTIONS } from '../../common/config/config';
class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
    this.showToaster = this.showToaster.bind(this);
  }

  showToaster(type, title, message) {
    this.toastr.add({
      type,
      title,
      position: 'top-left',
      attention: true,
      onAttentionClick: id => { },
      message,
      options: { showCloseButton: true },
      showCloseButton: true,
    });
  }

  componentWillMount() {
    this.getStateList();
  }

  getStateList = () => {
    this.props.dispatch({
      type: SAGA_ACTIONS.UTILITY.GET_STATE,
    });
  }

  stateChange = (e) => {
    console.log('stateChange', e)
    this.props.dispatch({
      type: SAGA_ACTIONS.UTILITY.GET_CITY,
      payload: {
        state_id: e.value
      }
    });
  }

  registrationFormSubmit = (data) => {
    console.log('values', data);
    var params = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      title_company: data.titlecompany,
      address: data.address,
      password: data.password,
      password_confirmation: data.confirmpassword,
      city: data.city && data.city.value ? data.city.value : null,
      state: data.state && data.state.value ? data.state.value : null,
      zipcode: data.zip,
    }
    console.log('params', params);

    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.REGISTRATION,
        payload: params,
        callbackSuccess: (resp) => {
          this.props.history.push('/login');
          console.log('success resp==', resp);
          this.showToaster('success', 'Success', resp.data.message);
        },
        callbackError: e => {
          const message = 'Token error';
          this.showToaster('error', 'Error', e.data.message);
          reject(new SubmissionError({ _error: e }));
        },
      });
      return resolve(true);
    });
  }

  render() {
    console.log('this.props error', this.props)
    const { handleSubmit, submitting } = this.props;
    const { stateList, cityList } = this.props;
    return (
      <div className="height-full">
        <div class="bg-login"></div>
        <div className="container registration-page">
          <div className="login-box registration-box">
            <div class="logo-company  mb-2">
              <img alt="Logo" src="assets/img/logo.png" />
            </div>
            <h4 className="text-center text-primary mb-4">Customer Portal</h4>
            <form name="registrationForm" onSubmit={handleSubmit(this.registrationFormSubmit)}>
              <div className="row">
                <div className="col-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend"><span className="input-group-text">
                      <svg className="c-icon">
                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-user" />
                      </svg></span></div>
                    <Field component={InputField} name="name" placeholder="Name" validate={[required]} />

                  </div>
                </div>
                <div className="col-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend"><span className="input-group-text">
                      <svg className="c-icon">
                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-envelope-open" />
                      </svg></span></div>
                    <Field component={InputField} name="email" placeholder="Email" validate={[required, emailValidator]} />

                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend"><span className="input-group-text">
                      <svg className="c-icon">
                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-phone" />
                      </svg></span></div>
                    <Field component={InputField} name="phone" placeholder="Phone" validate={[required, phoneValidator]} />

                  </div>
                </div>
                <div className="col-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend"><span className="input-group-text">
                      <svg className="c-icon">
                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-briefcase" />
                      </svg></span></div>
                    <Field component={InputField} name="titlecompany" placeholder="Title Company" validate={[required]} />
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend"><span className="input-group-text">
                      <svg className="c-icon">
                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-lock-locked" />
                      </svg></span></div>
                    <Field component={InputField} type="password" placeholder="Password" name="password" validate={[required]} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend"><span className="input-group-text">
                      <svg className="c-icon">
                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-lock-locked" />
                      </svg></span></div>
                    <Field component={InputField} type="password" placeholder="Confirm Password" name="confirmpassword" validate={[required]} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend"><span className="input-group-text">
                      <svg className="c-icon">
                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-address-book" />
                      </svg></span></div>
                    <Field component={InputField} name="address" placeholder="Address" validate={[required]} />

                  </div>
                </div>
                <div className="col-6">
                  <Field component={ReactRegSelect} name="state" placeholder="State" options={stateList} onChange={this.stateChange} validate={[required_dropdown]} />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  {/* <div className="form-group mb-3">
                    <div className="input-group-prepend city"><span className="input-group-text">
                      <svg className="c-icon">
                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-chart" />
                      </svg></span>
                      <Field component={ReactSelect} name="city" placeholder="City" options={cityList} validate={[required_dropdown]} />
                    </div>
                  </div> */}
                   <Field component={ReactRegSelect} name="city" placeholder="City" options={cityList} onChange={this.stateChange} validate={[required_dropdown]} />
                </div>
                <div className="col-6">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend"><span className="input-group-text">
                      <svg className="c-icon">
                        <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-location-pin" />
                      </svg></span></div>
                    <Field component={InputField} name="zip" placeholder="Zip" validate={[required]} />

                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <button className="btn btn-primary px-4" type="submit">Create Acccount</button>
                </div>
                <div className="col-6 text-right">
                  <span> <Link to={`/login`} className="text-primary">Already registered? Go to Login</Link></span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="footer">
          <div className="container">
            <span className="text-white">&copy; {(new Date().getFullYear())} <a className="text-white" href="https://app.landinspections.com" target="_blank">Land Inspections LLC </a>, All rights reserved. </span>
          </div>
        </footer>
      </div>
    );
  }
}

function processState(slist) {
  var state = [];
  slist.map(x => {
    var obj = { value: x.id, label: x.state_name }
    state.push(obj)
    var obj = {};
  })
  return state
}

function processCity(clist) {
  var city = [];
  clist.map(x => {
    var obj = { value: x.id, label: x.city_name }
    city.push(obj)
    var obj = {};
  })
  return city
}

const CreateRegistrationForm = reduxForm({
  form: 'registrationForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate(values) {
    const errors = {};
    if (values.password != values.confirmpassword) {
      errors.confirmpassword = 'password not matched';
    }
    return errors;
  },
})(Registration);
const mapStateToProps = state => {
  const stateList = state.utility && state.utility.stateList && state.utility.stateList.data && state.utility.stateList.data.length ? processState(state.utility.stateList.data[0]) : [];
  const cityList = state.utility && state.utility.cityList && state.utility.cityList.data && state.utility.cityList.data.length ? processCity(state.utility.cityList.data[0]) : [];
  return {
    stateList,
    cityList
  };
};

export default connect(mapStateToProps)(CreateRegistrationForm);
