import React, { Component } from 'react';
import { connect } from "react-redux";
import Switch from "react-switch";
import moment from 'moment';
// import Header from '../Common/Header';
// import Sidebar from '../Common/Sidebar';
// import Footer from '../Common/Footer';
import { SAGA_ACTIONS, ACTIONS } from "../../common/config/config";
class FriendList extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentWillMount() {
    console.log('friend list called')
    this.getFriendList();
  }

  getFriendList = () => {
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: SAGA_ACTIONS.MESSAGES.GET_FRIEND_LIST,
        callbackSuccess: (resp) => {
          console.log('resp', resp);
        },
        callbackError: e => {
          console.log('error', e)
          reject(e);
        },
      });
      return resolve(true);
    });
  }

  getChatDetails=(frnd)=>{
    // console.log('friend id',frndId)
    // console.log('me details',this.props.meDetails.id)
    this.props.dispatch({
      type: ACTIONS.MESSAGES.SELECTED_CHAT_FRIEND,
      payload:{
        selectedFriend:frnd.id,
        order_id:frnd.order_id
      },
    });

    this.props.dispatch({
      type: ACTIONS.MESSAGES.EMPTY_MSG_DETAILS_WITH_PARTICULAR_FRND,
      payload:null,
    });

    var params ={
      me_user:this.props.meDetails&& this.props.meDetails.id?this.props.meDetails.id:null,
      friend_user:frnd.id,
      order_id:frnd.order_id
    }
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: SAGA_ACTIONS.MESSAGES.GET_MSG_WITH_PARTICULAR_FRND,
        payload:params,
        callbackSuccess: (resp) => {
          console.log('resp', resp);
        },
        callbackError: e => {
          console.log('error', e)
          reject(e);
        },
      });
      return resolve(true);
    });
  }
  dateProcess = (data) => {
    var localTime  = moment.utc(data).toDate();
    localTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
    var formatDate = moment(localTime).calendar();
    
    // var formatDate = moment(data).calendar(); //previous code
   
    return (formatDate)
  }

  render() {
    const { friendList } = this.props
    return (
      <div className="inbox_chat">
       
        { friendList &&  friendList &&  friendList.length ? (
           friendList.map((friend,i) => {
            return (
              <div key={i} className="chat_list active_chat" onClick={()=>this.getChatDetails(friend)}>
              <div className="chat_people">
                <div className="chat_img"> <img src={friend&&friend.profile_pic?friend.profile_pic:'https://ptetutorials.com/images/user-profile.png'} alt={friend.name} /> </div>
                <div className="chat_ib">
            <h5><strong>{friend.order_number}</strong> - {friend.name}<span className="chat_date">{this.dateProcess(friend.time.date)}</span></h5>
                  {/* <h5>{friend.name}</h5> */}
            <p>{friend.message} {friend.not_read_chat?<span className="unread">{friend.not_read_chat}</span>:null}</p>
                </div>
              </div>
            </div>
            );
          })
        ) : (
          <div className="chat_list active_chat">
          <div className="chat_people">
            <div className="chat_ib">
              <h5>No Friend Found</h5>
            </div>
          </div>
        </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const friendList = state.message&&state.message.friendList && state.message.friendList.data && state.message.friendList.data.length ? state.message.friendList.data : [];
  const meDetails = state.user && state.user.user && state.user.user.data && state.user.user.data.length? state.user.user.data[0] : null;

  return {
    friendList,
    meDetails
  };
};

export default connect(mapStateToProps)(FriendList);