import React from 'react';
import { NavLink } from "react-router-dom";
function NotFound() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="clearfix">
            <h1 className="float-left display-3 mr-4">404</h1>
            <h4 className="pt-3">Oops! You're lost.</h4>
            <p className="text-muted">The page you are looking for was not found.</p>
          </div>
          {/* <div className="input-prepend input-group">
            <div className="input-group-prepend"><span className="input-group-text">
              
              </span></div>
           
             
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default NotFound;
